import React from "react";
import { Link } from "react-router-dom";

import gql from 'graphql-tag';
import { graphql } from 'react-apollo';

import {
  UncontrolledCollapse,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col,
} from "reactstrap";

const GET_NAVBAR = gql`
  {
	  navBar{
      brandName
      logo
      menuList{
        title
        url
      }
    }
}
`;

class ColorNavbar extends React.Component {
  state = {
    navbarColor: "navbar-transparent",
    brandName: "Empresa Original",
    logo: "Empresa Original",
    menuList: [
      { id: "4", icon: "icon url 4", title: "Services", url: "/services-page", type: "navBarMenuList" },
      { id: "5", icon: "icon url 5", title: "Portfolio", url: "/portfolio-page", type: "navBarMenuList" },
      { id: "6", icon: "icon url 6", title: "Team", url: "/team", type: "navBarMenuList" },

      // { id: "7", icon: "icon url 7", title: "Pricing", url: "/pricing", type: "navBarMenuList" },
      { id: "8", icon: "icon url 8", title: "Blog", url: "/blog-posts", type: "navBarMenuList" },
      { id: "9", icon: "icon url 9", title: "Contact Us", url: "/contact-us", type: "navBarMenuList" },
      { id: "10", icon: "icon url 10", title: "About Us", url: "/about-us", type: "navBarMenuList" },
    ]
  };

  componentDidMount() {
    window.addEventListener("scroll", this.changeNavbarColor);
    // this.getNavBarData();
    this.generateNavBarItem();
  }
  componentWillUnmount() {
    window.removeEventListener("scroll", this.changeNavbarColor);
  }
  changeNavbarColor = () => {
    if (
      document.documentElement.scrollTop > 200 ||
      document.body.scrollTop > 200
    ) {
      this.setState({
        navbarColor: "bg-dark"
      });
    } else if (
      document.documentElement.scrollTop < 201 ||
      document.body.scrollTop < 201
    ) {
      this.setState({
        navbarColor: "navbar-transparent"
      });
    }
  };

  getNavBarData() {
    var data = this.props.data;

    if (!data.loading) {
      const getNavBarData = {
        brandName: data.navBar[0].brandName,
        logo: data.navBar[0].logo,
        menuList: data.navBar[0].menuList
      }
      this.setState({
        brandName: getNavBarData.brandName,
        logo: getNavBarData.logo,
        menuList: getNavBarData.menuList
      })
    } else {
      console.log(data);
    }
    this.generateNavBarItem();
  };

  generateNavBarItem() {
    if (this.state.menuList.length !== 0) {
      return (
        this.state.menuList.map(item => {
          return (
            <NavItem key={item.id}>
              <NavLink to={item.url} tag={Link}>
                {item.title}
              </NavLink>
            </NavItem>
          );
        })
      )
    } else {
      console.log("item not found");
    }
  }

  render() {
    return (
      <>
        <Navbar className={"fixed-top " + this.state.navbarColor} expand="lg" >
          <Container>
            <div className="navbar-translate">
              <NavbarBrand to="/index" tag={Link} id="tooltip6619950104">
                {/* {this.state.brandName} */}
                <img
                  alt="..."
                  className="img rounded"
                  src={require("assets/img/empresa-mini-logo-white.png")}
                  height="58px"
                />
              </NavbarBrand>
              <button className="navbar-toggler" id="navigation">
                <span className="navbar-toggler-bar bar1" />
                <span className="navbar-toggler-bar bar2" />
                <span className="navbar-toggler-bar bar3" />
              </button>
            </div>
            <UncontrolledCollapse navbar toggler="#navigation">
              <div className="navbar-collapse-header">
                <Row>
                  <Col className="collapse-brand" xs="6">
                    <a href="#pablo" onClick={e => e.preventDefault()}>
                      {/* {this.state.brandName} */}
                      <img
                        alt="..."
                        className="img rounded"
                        src={require("assets/img/empresaoriginal-logo-white.png")}
                        height="48px"
                      />
                    </a>
                  </Col>
                  <Col className="collapse-close text-right" xs="6">
                    <button className="navbar-toggler" id="navigation">
                      <i className="tim-icons icon-simple-remove" />
                    </button>
                  </Col>
                </Row>
              </div>
              <Nav className="ml-auto" navbar>
                {this.generateNavBarItem()}
              </Nav>
            </UncontrolledCollapse>
          </Container>
        </Navbar>
      </>
    );
  }
}

export default graphql(GET_NAVBAR)(ColorNavbar);
