import React from "react";
// Importing GQL Fetch function
import { gql as graphQL } from "../../services/blog-api-service";
// Importing GraphQL query
import { GET_USER_ARTICLES, GET_USER_INFO } from "../../services/blog-api-service";

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Container,
  Row,
  Col,
} from "reactstrap";
import DemoFooter from "components/Footers/DemoFooter";
import ColorNavbar from "components/Navbars/ColorNavbar";
import moment from "moment";

// React router dom
import { Link } from "react-router-dom";

// Importing Teams data
import { Teams as users } from "../../data/team";

// core components
const items = [
  {
    content: (
      <Container>
        <Row>
          <Col md="4">
            <h1 className="title">Charlie Watson</h1>
          </Col>
          <Col md="4">
            <img
              alt="..."
              className="d-block"
              src={require("assets/img/charlie.jpg")}
            />
          </Col>
          <Col md="4">
            <div className="wrapper">
              <div className="category">
                <strong>Position:</strong> Artist <br />
                <strong>Experience:</strong> 5 years
              </div>
              <div className="description">
                Artist is a term applied to a person who engages in an activity
                deemed to be an art. An artist also may be defined unofficially
                as "a person who expresses him- or herself through a medium". He
                is a descriptive term applied to a person who engages in an
                activity deemed to be an art. An artist also may be defined
                unofficially as "a person who expresses him- or herself through
                a medium".
              </div>
              <div className="footer">
                <Button
                  className="btn-icon btn-round"
                  color="twitter"
                  href="#pablo"
                  onClick={(e) => e.preventDefault()}
                >
                  <i className="fab fa-twitter" />
                </Button>
                <Button
                  className="btn-icon btn-round"
                  color="facebook"
                  href="#pablo"
                  onClick={(e) => e.preventDefault()}
                >
                  <i className="fab fa-facebook-square" />
                </Button>
                <Button
                  className="btn-icon btn-round"
                  color="dribbble"
                  href="#pablo"
                  onClick={(e) => e.preventDefault()}
                >
                  <i className="fab fa-dribbble" />
                </Button>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    ),
    altText: "",
    caption: "",
    src: "0",
  },
  {
    content: (
      <Container>
        <Row>
          <Col md="4">
            <h1 className="title">Quavo Austen</h1>
          </Col>
          <Col md="4">
            <img
              alt="..."
              className="d-block"
              src={require("assets/img/tom-klein.jpg")}
            />
          </Col>
          <Col md="4">
            <div className="wrapper">
              <div className="category">
                <strong>Position:</strong> Actor <br />
                <strong>Experience:</strong> 1 year
              </div>
              <div className="description">
                Artist is a term applied to a person who engages in an activity
                deemed to be an art. An artist also may be defined unofficially
                as "a person who expresses him- or herself through a medium". He
                is a descriptive term applied to a person who engages in an
                activity deemed to be an art. An artist also may be defined
                unofficially as "a person who expresses him- or herself through
                a medium".
              </div>
              <div className="footer">
                <Button
                  className="btn-icon btn-round"
                  color="twitter"
                  href="#pablo"
                  onClick={(e) => e.preventDefault()}
                >
                  <i className="fab fa-twitter" />
                </Button>
                <Button
                  className="btn-icon btn-round"
                  color="facebook"
                  href="#pablo"
                  onClick={(e) => e.preventDefault()}
                >
                  <i className="fab fa-facebook-square" />
                </Button>
                <Button
                  className="btn-icon btn-round"
                  color="dribbble"
                  href="#pablo"
                  onClick={(e) => e.preventDefault()}
                >
                  <i className="fab fa-dribbble" />
                </Button>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    ),
    altText: "",
    caption: "",
    src: "1",
  },
  {
    content: (
      <Container>
        <Row>
          <Col md="4">
            <h1 className="title">Lucy</h1>
          </Col>
          <Col md="4">
            <img
              alt="..."
              className="d-block"
              src={require("assets/img/lucy.jpg")}
            />
          </Col>
          <Col md="4">
            <div className="wrapper">
              <div className="category">
                <strong>Position:</strong> CEO <br />
                <strong>Experience:</strong> 7 years
              </div>
              <div className="description">
                Artist is a term applied to a person who engages in an activity
                deemed to be an art. An artist also may be defined unofficially
                as "a person who expresses him- or herself through a medium". He
                is a descriptive term applied to a person who engages in an
                activity deemed to be an art. An artist also may be defined
                unofficially as "a person who expresses him- or herself through
                a medium".
              </div>
              <div className="footer">
                <Button
                  className="btn-icon btn-round"
                  color="twitter"
                  href="#pablo"
                  onClick={(e) => e.preventDefault()}
                >
                  <i className="fab fa-twitter" />
                </Button>
                <Button
                  className="btn-icon btn-round ml-1"
                  color="facebook"
                  href="#pablo"
                  onClick={(e) => e.preventDefault()}
                >
                  <i className="fab fa-facebook-square" />
                </Button>
                <Button
                  className="btn-icon btn-round ml-1"
                  color="dribbble"
                  href="#pablo"
                  onClick={(e) => e.preventDefault()}
                >
                  <i className="fab fa-dribbble" />
                </Button>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    ),
    altText: "",
    caption: "",
    src: "2",
  },
];

const GenerateRecentBlogCards = (props) => {
  return !props.blogs.length ? (
    <div className="ml-auto mr-auto text-center">Loading...</div>
  ) : (
    <>
      {props.blogs.map((article, id) => {
        if (article) {
          return (
            <Card className="card-blog card-plain blog-horizontal" key={id}>
              <Row>
                <Col lg="4">
                  <div className="card-image">
                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                      <img
                        alt="..."
                        className="img rounded"
                        src={article.coverImage}
                      />
                    </a>
                  </div>
                </Col>
                <Col lg="8">
                  <CardBody>
                    <CardTitle tag="h3">
                      <Link to={"/blog-post/" + article.slug}>
                        {article.title}
                      </Link>
                    </CardTitle>
                    <p className="card-description">
                      {article.brief}{" "}
                      <Link to={"/blog-post/" + article.slug}>
                        Read more
                      </Link>
                    </p>
                    <div className="author">
                      <img
                        alt="..."
                        className="avatar img-raised"
                        src={article.author.photo}
                      />
                      <div className="text">
                        <span className="name">@{article.author.username}</span>
                        <div className="meta">
                          {moment(article.dateAdded).fromNow()}
                        </div>
                      </div>
                    </div>
                  </CardBody>
                </Col>
              </Row>
            </Card>
          );
        } else {
          return <h2>No article found!</h2>;
        }
      })}
    </>
  );
};

const GenerateTeamCards = (props) => {
  console.log(props);
  return !Object.keys(props.authors).length ? (
    <div className="ml-auto mr-auto text-center">Loading...</div>
  ) : (
    <>
      {
        Object.entries(props.authors).map(([username, userInfo]) => {
          let profileLink = `/profile-page/${username}`;
          let profileCoverImage = userInfo.coverImage;
          return (
            <Col md="4" key={username}>
              <Card className="card-profile profile-bg">
                <CardHeader
                  style={{
                    backgroundImage:
                      "url(" + profileCoverImage + ")",
                  }}
                >
                  <div className="card-avatar">
                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                      <img
                        alt="..."
                        className="img img-raised"
                        src={userInfo.photo}
                      />
                    </a>
                  </div>
                </CardHeader>
                <CardBody>
                  <CardTitle tag="h3">{userInfo.name}</CardTitle>
                  <h6 className="category text-primary">
                    {
                      props.teams.find
                        (team => team.username === userInfo.username)
                        .tagline
                    }
                  </h6>
                  <p className="card-description">
                    {
                      props.teams.find
                        (team => team.username === userInfo.username)
                        .description
                    }
                  </p>
                </CardBody>
                <CardFooter>
                  <div className="follow float-left">
                    {

                    }
                    <Button
                      className="btn-simple"
                      color="primary"
                      href={profileLink}
                      size="sm"
                    >
                      <i className="tim-icons icon-minimal-right mr-1" />
                      View Profile
                    </Button>
                  </div>
                  <div className="d-inline float-right d-md-block">
                    <Button
                      className="btn-icon btn-round"
                      color="twitter"
                      href={userInfo.socialMedia.twitter}
                      id="tooltiptwitteraakarkun"
                      target="_blank"
                    >
                      <i className="fab fa-twitter" />
                    </Button>
                    <Button
                      className="btn-icon btn-round"
                      color="facebook"
                      href={userInfo.socialMedia.facebook}
                      id="tooltipfbkusgautam"
                      target="_blank"
                    >
                      <i className="fab fa-facebook-square" />
                    </Button>
                    <Button
                      className="btn-icon btn-round"
                      color="linkedin"
                      href={userInfo.socialMedia.linkedin}
                      id="tooltiplinkedinkusgautam"
                      target="_blank"
                    >
                      <i className="fab fa-linkedin" />
                    </Button>
                  </div>
                </CardFooter>
              </Card>
            </Col>
          )
        })
      }
    </>
  )
}

class Teams extends React.Component {
  state = {
    activeIndex: 0,
    teams: users,
    articles: [],
    authors: {}
  };
  onExiting = () => {
    this.animating = true;
  };

  onExited = () => {
    this.animating = false;
  };

  next = () => {
    if (this.animating) return;
    const nextIndex =
      this.state.activeIndex === items.length - 1
        ? 0
        : this.state.activeIndex + 1;
    this.setState({ activeIndex: nextIndex });
  };

  previous = () => {
    if (this.animating) return;
    const nextIndex =
      this.state.activeIndex === 0
        ? items.length - 1
        : this.state.activeIndex - 1;
    this.setState({ activeIndex: nextIndex });
  };

  goToIndex = (newIndex) => {
    if (this.animating) return;
    this.setState({ activeIndex: newIndex });
  };

  componentDidMount() {
    this.state.teams.map((team) => {
      // Fetching articles using GraphQL
      graphQL(GET_USER_ARTICLES, {
        page: 0,
        user: team.username,
      }).then((result) => {
        let articles = result.data.user.publication;
        let posts = this.state.articles;
        if (articles) {
          articles.posts.map((article) => {
            posts.push(article);
          });
          this.setState({
            articles: posts.sort(
              (a, b) =>
                new moment(b.dateAdded).format("YYYYMMDD") -
                new moment(a.dateAdded).format("YYYYMMDD")
            ),
          });
        } else {
          console.log(`No article(s) found!`);
        }
      });

      // Fetching all user informatuon 
      graphQL(GET_USER_INFO, {
        page: 0,
        user: team.username,
      }).then((result) => {
        let info = result.data.user;
        let tempUser = this.state.authors;
        if (info) {
          tempUser[team.username] = info;
        } else {
          console.log(`This '${this.state.username}' not found in HashNode!`);
        }
        this.setState({
          authors: tempUser
        });
      });
    });
  }

  render() {
    return (
      <>
        <ColorNavbar />
        <div className="cd-section" id="teams">
          {/* ********* TEAM 5 ********* */}
          <div className="team-5 empresa-team">
            <Container>
              <Row>
                <Col className="ml-auto mr-auto text-center" md="8">
                  <h2 className="title">We are Empresa</h2>
                  <h4 className="description">
                    Team, build with trust and intelligence, with industry
                    leading experience in multiple projects. Helping companies
                    grow and generate more profits at lower costs.
                  </h4>
                </Col>
              </Row>
              <Row>
                {/* Fetched user info for Team cards */}
                <GenerateTeamCards authors={this.state.authors} teams={users} />
              </Row>
              <Row>
                <Col className="ml-auto mr-auto text-center" md="8">
                  <h4 className="description">
                    To know more about who we are feel free to contact in any
                    social media platform.
                  </h4>
                </Col>
              </Row>
            </Container>
          </div>
          {/* ********* END TEAM 5 ********* */}
          {/* Recent Blog Post from each team member  */}
          <div className="section">
            <Container>
              <Row>
                <Col className="ml-auto mr-auto" md="12">
                  <h2 className="title ml-1">Recent blog post</h2>
                  <br />
                  <GenerateRecentBlogCards blogs={this.state.articles} />
                </Col>
              </Row>
            </Container>
          </div>
          {/* ********* BLOGS 5 ********* */}
          {/* <div className="blogs-5">
            <Container>
              <Row>
                <Col className="ml-auto mr-auto" md="10">
                  <h2 className="title">Latest Blogposts 5</h2>
                  <Row>
                    <Col lg="4" md="6">
                      <Card className="card-blog card-plain">
                        <div className="card-image">
                          <a href="#pablo" onClick={e => e.preventDefault()}>
                            <img
                              alt="..."
                              className="img rounded"
                              src={require("assets/img/steven-roe.jpg")}
                            />
                          </a>
                        </div>
                        <CardBody>
                          <h6 className="category text-primary">Features</h6>
                          <CardTitle tag="h4">
                            <a href="#pablo" onClick={e => e.preventDefault()}>
                              That’s One Way To Ditch Your Passenger
                            </a>
                          </CardTitle>
                          <p className="card-description">
                            As near as we can tell, this guy must have thought
                            he was going over backwards and tapped the rear...
                          </p>
                          <CardFooter>
                            <div className="author">
                              <img
                                alt="..."
                                className="avatar img-raised"
                                src={require("assets/img/p10.jpg")}
                              />
                              <span className="ml-1">Mike John</span>
                            </div>
                            <div className="stats stats-right">
                              <i className="tim-icons icon-watch-time" /> 5 min
                              read
                            </div>
                          </CardFooter>
                        </CardBody>
                      </Card>
                    </Col>
                    <Col lg="4" md="6">
                      <Card className="card-blog card-plain">
                        <div className="card-image">
                          <a href="#pablo" onClick={e => e.preventDefault()}>
                            <img
                              alt="..."
                              className="img rounded"
                              src={require("assets/img/noah-wetering.jpg")}
                            />
                          </a>
                        </div>
                        <CardBody>
                          <h6 className="category text-info">Animals</h6>
                          <CardTitle tag="h4">
                            <a href="#pablo" onClick={e => e.preventDefault()}>
                              Shark Week: How to Watch It Like a Scientist
                            </a>
                          </CardTitle>
                          <p className="card-description">
                            Just when you thought it was safe to turn on your
                            television, the Discovery Channel's "Shark Week"...
                          </p>
                          <CardFooter>
                            <div className="author">
                              <img
                                alt="..."
                                className="avatar img-raised"
                                src={require("assets/img/johana.jpg")}
                              />
                              <span className="ml-1">Jona Zmud</span>
                            </div>
                            <div className="stats stats-right">
                              <i className="tim-icons icon-watch-time" /> 5 min
                              read
                            </div>
                          </CardFooter>
                        </CardBody>
                      </Card>
                    </Col>
                    <Col lg="4" xs="12">
                      <Card className="card-blog card-plain">
                        <div className="card-image">
                          <a href="#pablo" onClick={e => e.preventDefault()}>
                            <img
                              alt="..."
                              className="img rounded"
                              src={require("assets/img/mark-harrison.jpg")}
                            />
                          </a>
                        </div>
                        <CardBody>
                          <h6 className="category text-warning">Cars</h6>
                          <CardTitle tag="h4">
                            <a href="#pablo" onClick={e => e.preventDefault()}>
                              Who's Afraid of the Self-Driving Car?
                            </a>
                          </CardTitle>
                          <p className="card-description">
                            It's been 60 years since the cover of Popular
                            Mechanics magazine gave us the promise of flying
                            cars...
                          </p>
                          <CardFooter>
                            <div className="author">
                              <img
                                alt="..."
                                className="avatar img-raised"
                                src={require("assets/img/christian.jpg")}
                              />
                              <span className="ml-1">Marc Oliver</span>
                            </div>
                            <div className="stats stats-right">
                              <i className="tim-icons icon-heart-2" /> 2.4K
                            </div>
                          </CardFooter>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Container>
          </div> */}
          {/* ********* END BLOGS 5 ********* */}
          <DemoFooter />
        </div>{" "}
      </>
    );
  }
}

export default Teams;
