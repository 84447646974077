import React from "react";

import gql from 'graphql-tag';
import {graphql} from 'react-apollo';

// nodejs library that concatenates classes
import classnames from "classnames";
// reactstrap components
import {
  Badge,
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col
} from "reactstrap";

// core components
import ColorNavbar from "components/Navbars/ColorNavbar.js";
import DemoFooter from "components/Footers/DemoFooter.js";

const GET_PACKAGE = gql`
  {
	  contentSection {
    title
    packagePlanItem {
      title
      price
      servicesList {
        icon
        title
      }
    }
    description
  }
}
`;

class Pricing extends React.Component {
  

  constructor(props) {
    super(props);

    this.state = {
      title: "",
      packagePlanItem: [],
      description: "",
      sections: ""
    };
  }

  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.wrapper.scrollTop = 0;
    document.body.classList.add("pricing");
    console.log(this.getPackageData());
  }

  componentWillUnmount() {
    document.body.classList.remove("pricing");
  }

  getPackageData() {
    var data = this.props.data;
    var sections = data.loading;
    return sections;
  };

  render() {
    

    return (
      <>
        <ColorNavbar />
        <div className="wrapper" ref="wrapper">
          <div className="page-header header-filter page-header-small">
            <div
              className="page-header-image"
              style={{
                backgroundImage:
                  "url(" + require("assets/img/lucas-benjamin.jpg") + ")"
              }}
            />
            <Container>
              <Row>
                <Col className="ml-auto mr-auto text-center" md="8">
                  <h1 className="title">Our Pricing Plan</h1>
                  <h4 className="description">
                    To get started, you will need to choose a plan for your
                    needs. You have Free Unlimited Updates and Premium Support
                    on each package.
                  </h4>
                </Col>
              </Row>
            </Container>
          </div>
          <div className="main">
            <div className="pricing-2 project-raised">
              <Container>
                <Row>
                  <Col md="4">
                    <Card className="card-pricing">
                      <CardHeader className="bg-danger">
                        <Badge className="badge-default">Pro</Badge>
                        <CardTitle tag="h1">
                          <small>$</small>
                          45
                        </CardTitle>
                        <h5>per month</h5>
                      </CardHeader>
                      <CardBody>
                        <ul>
                          <li>
                            <i className="tim-icons icon-check-2" /> 2000 MB
                          </li>
                          <li>
                            <i className="tim-icons icon-email-85" /> 100 email
                          </li>
                          <li>
                            <i className="tim-icons icon-chart-bar-32" /> 10
                            Databases
                          </li>
                          <li>
                            <i className="tim-icons icon-alert-circle-exc" />{" "}
                            Support
                          </li>
                        </ul>
                        <Button
                          className="mt-4"
                          color="info"
                          href="#pablo"
                          onClick={e => e.preventDefault()}
                        >
                          Get started
                        </Button>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col md="4">
                    <Card className="card-pricing">
                      <CardHeader className="bg-info">
                        <Badge className="badge-default">Starter</Badge>
                        <CardTitle tag="h1">
                          <small>$</small>
                          22
                        </CardTitle>
                        <h5>per month</h5>
                      </CardHeader>
                      <CardBody>
                        <ul>
                          <li>
                            <i className="tim-icons icon-check-2" /> 1000 MB
                          </li>
                          <li>
                            <i className="tim-icons icon-email-85" /> 3 email
                          </li>
                          <li>
                            <i className="tim-icons icon-chart-bar-32" /> 5
                            Databases
                          </li>
                          <li>
                            <i className="tim-icons icon-alert-circle-exc" /> No
                            Support
                          </li>
                        </ul>
                        <Button
                          className="mt-4"
                          color="info"
                          href="#pablo"
                          onClick={e => e.preventDefault()}
                        >
                          Get started
                        </Button>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col md="4">
                    <Card className="card-pricing">
                      <CardHeader className="bg-warning">
                        <Badge className="badge-default">Enterprise</Badge>
                        <CardTitle tag="h1">
                          <small>$</small>
                          68
                        </CardTitle>
                        <h5>per month</h5>
                      </CardHeader>
                      <CardBody>
                        <ul>
                          <li>
                            <i className="tim-icons icon-check-2" /> 10.000 MB
                          </li>
                          <li>
                            <i className="tim-icons icon-email-85" /> Unlimited
                            emails
                          </li>
                          <li>
                            <i className="tim-icons icon-chart-bar-32" /> 50
                            Databases
                          </li>
                          <li>
                            <i className="tim-icons icon-alert-circle-exc" />{" "}
                            Premium Support
                          </li>
                        </ul>
                        <Button
                          className="mt-4"
                          color="info"
                          href="#pablo"
                          onClick={e => e.preventDefault()}
                        >
                          Get started
                        </Button>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </Container>
            </div>
            <div className="features-1">
              <Container>
                <Row>
                  <Col className="ml-auto mr-auto" md="8">
                    <Badge color="primary" pill>
                      Insight
                    </Badge>
                    <h1 className="title">Full-Funnel Social Analytics</h1>
                  </Col>
                </Row>
                <Row>
                  <Col md="4">
                    <div className="info info-hover">
                      <div className="icon icon-primary">
                        <img
                          alt="..."
                          className="bg-blob"
                          src={require("assets/img/feature-blob/primary.png")}
                        />
                        <i className="tim-icons icon-user-run" />
                      </div>
                      <h4 className="info-title">Social Conversations</h4>
                      <p className="description">
                        Gain access to the demographics, psychographics, and
                        location of unique people.
                      </p>
                    </div>
                  </Col>
                  <Col md="4">
                    <div className="info info-hover">
                      <div className="icon icon-success">
                        <img
                          alt="..."
                          className="bg-blob"
                          src={require("assets/img/feature-blob/success.png")}
                        />
                        <i className="tim-icons icon-atom" />
                      </div>
                      <h4 className="info-title">Analyze Performance</h4>
                      <p className="description">
                        Unify data from Facebook, Instagram, Twitter, LinkedIn,
                        and Youtube to gain rich insights.
                      </p>
                    </div>
                  </Col>
                  <Col md="4">
                    <div className="info info-hover">
                      <div className="icon icon-warning">
                        <img
                          alt="..."
                          className="bg-blob"
                          src={require("assets/img/feature-blob/warning.png")}
                        />
                        <i className="tim-icons icon-gift-2" />
                      </div>
                      <h4 className="info-title">Measure Conversions</h4>
                      <p className="description">
                        Track actions taken on your website, understand the
                        impact on your bottom line.
                      </p>
                    </div>
                  </Col>
                </Row>
              </Container>
            </div>
            <div className="contactus-3">
              <div className="page-header header-filter">
                <div
                  className="page-header-image"
                  style={{
                    backgroundImage:
                      "url(" + require("assets/img/andre-benz.jpg") + ")"
                  }}
                />
                <Container>
                  <Row>
                    <Col className="text-center" md="12">
                      <h1 className="title">Got a question?</h1>
                      <h3>We'd like to talk more about what you need</h3>
                    </Col>
                  </Row>
                </Container>
              </div>
              <Container fluid>
                <Row>
                  <Col md="3">
                    <div className="info info-hover">
                      <div className="icon icon-primary">
                        <img
                          alt="..."
                          className="bg-blob"
                          src={require("assets/img/feature-blob/primary.png")}
                        />
                        <i className="tim-icons icon-square-pin" />
                      </div>
                      <h4 className="info-title">Address</h4>
                      <p className="description">55/29 Mary St, Auburn <br/> 2144 NSW, Australia </p>
                    </div>
                  </Col>
                  <Col md="3">
                    <div className="info info-hover">
                      <div className="icon icon-info">
                        <img
                          alt="..."
                          className="bg-blob"
                          src={require("assets/img/feature-blob/info.png")}
                        />
                        <i className="tim-icons icon-email-85" />
                      </div>
                      <h4 className="info-title">Email</h4>
                      <p className="description">info@empresaoriginal.com</p>
                    </div>
                  </Col>
                  <Col md="3">
                    <div className="info info-hover">
                      <div className="icon icon-warning">
                        <img
                          alt="..."
                          className="bg-blob"
                          src={require("assets/img/feature-blob/warning.png")}
                        />
                        <i className="tim-icons icon-mobile" />
                      </div>
                      <h4 className="info-title">Phone Number</h4>
                      <p className="description">+61 450 253 218</p>
                    </div>
                  </Col>
                  <Col md="3">
                    <div className="info info-hover">
                      <div className="icon icon-success">
                        <img
                          alt="..."
                          className="bg-blob"
                          src={require("assets/img/feature-blob/success.png")}
                        />
                        <i className="tim-icons icon-single-02" />
                      </div>
                      <h4 className="info-title">Need Support?</h4>
                      <p className="description">24/7 Assistant Available</p>
                    </div>
                  </Col>
                </Row>
              </Container>
            </div>
            <div className="section projects-2">
              <Container>
                <Row>
                  <Col className="ml-auto mr-auto text-center" md="8">
                    <h2 className="title">We continually work to improve</h2>
                    <h4 className="description">
                      By accepting this, you will be kept up to date on new
                      developments concerning the CMS
                    </h4>
                  </Col>
                </Row>
                <Row className="mt-5">
                                                <Col md="4">
                                                    <Card>
                                                        <div className="card-image h-300">
                                                            <img
                                                                alt="..."
                                                                className="img rounded"
                                                                src={require("assets/img/projects/thumbnail-freechoicegroup2.jpg")}
                                                            />
                                                        </div>
                                                        <CardBody className="text-left">
                                                            <CardFooter className="mt-0">
                                                                {<div className="stats stats-right">
                                                                    <div className="stars text-warning">
                                                                        <i className="tim-icons icon-square-pin ml-2" />
                                                                        <span>Australia</span>
                                                                    </div>
                                                                </div>}
                                                                <div className="author">
                                                                    <img
                                                                        alt="..."
                                                                        className="avatar img-raised"
                                                                        src={require("assets/img/projects/freechoice-favico.jpg")}
                                                                    />
                                                                    <span className="ml-1">FreeChoice Group</span>
                                                                </div>
                                                            </CardFooter>
                                                        </CardBody>
                                                    </Card>
                                                </Col>
                                                <Col md="4">
                                                    <Card>
                                                        <div className="card-image h-100">
                                                            <img
                                                                alt="..."
                                                                className="img rounded"
                                                                src={require("assets/img/projects/thumbnail-drcleanaustralia.jpg")}
                                                            />
                                                        </div>
                                                        <CardBody className="text-left">
                                                            <CardFooter className="mt-0">
                                                                <div className="stats stats-right text-primary">
                                                                    <i className="tim-icons icon-square-pin ml-2" />
                                                                    <span>Australia</span>
                                                                </div>
                                                                <div className="author">
                                                                    <img
                                                                        alt="..."
                                                                        className="avatar img-raised"
                                                                        src={require("assets/img/projects/thumbnail-drcleanaustralia.jpg")}
                                                                    />
                                                                    <span className="ml-1">
                                                                        DR. CLEAN Australia
                                                                    </span>
                                                                </div>
                                                            </CardFooter>
                                                        </CardBody>
                                                    </Card>
                                                </Col>
                                                <Col md="4">
                                                    <Card>
                                                        <div className="card-image h-100">
                                                            <img
                                                                alt="..."
                                                                className="img rounded"
                                                                src={require("assets/img/projects/thumbnail-unic.jpg")}
                                                            />
                                                        </div>
                                                        <CardBody className="text-left">
                                                            <CardFooter className="mt-0">
                                                                <div className="stats stats-right text-danger">
                                                                    <i className="tim-icons icon-square-pin ml-2" />
                                                                    <span>Nepal</span>
                                                                </div>
                                                                <div className="author">
                                                                    <img
                                                                        alt="..."
                                                                        className="avatar img-raised"
                                                                        src={require("assets/img/projects/thumbnail-unic.jpg")}
                                                                    />
                                                                    <span className="ml-1">
                                                                        UNIC Nepal
                                                                    </span>
                                                                </div>
                                                            </CardFooter>
                                                        </CardBody>
                                                    </Card>
                                                </Col>
                                            </Row>
              </Container>
            </div>
            <div className="subscribe-line">
              <Container>
                <Row>
                  <Col className="ml-auto mr-auto" lg="6" md="10">
                    <div className="text-center">
                      <h2 className="title">Subscribe to our Newsletter</h2>
                      <p className="description">
                        You'll be informed about updates, special offers and
                        planned changes..
                      </p>
                    </div>
                    <Card className="card-raised card-form-horizontal">
                      <CardBody>
                        <Form action="" method="">
                          <Row>
                            <Col sm="8">
                              <InputGroup
                                className={classnames({
                                  "input-group-focus": this.state.emailFocus
                                })}
                              >
                                <InputGroupAddon addonType="prepend">
                                  <InputGroupText>
                                    <i className="tim-icons icon-email-85" />
                                  </InputGroupText>
                                </InputGroupAddon>
                                <Input
                                  placeholder="Your Email..."
                                  type="email"
                                  onFocus={e =>
                                    this.setState({ emailFocus: true })
                                  }
                                  onBlur={e =>
                                    this.setState({ emailFocus: false })
                                  }
                                />
                              </InputGroup>
                            </Col>
                            <Col sm="4">
                              <Button color="primary" type="button">
                                Subscribe
                              </Button>
                            </Col>
                          </Row>
                        </Form>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </Container>
            </div>
          </div>
          <DemoFooter />
        </div>
      </>
    );
  }
}

export default graphql(GET_PACKAGE)(Pricing);
