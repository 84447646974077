import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

// styles
import "assets/css/nucleo-icons.css";
import "assets/scss/blk-design-system-pro-react.scss?v1.1.0";
import "assets/demo/demo.css";
import "assets/demo/style.css";
import "assets/demo/react-demo.css";

// presentation pages
import Index from "views/Index.js";
import Presentation from "views/Presentation.js";
import Sections from "views/Sections.js";
// example pages
import AboutUs from "views/examples/AboutUs.js";
import BlogPost from "views/examples/BlogPost.js";
import BlogPosts from "views/examples/BlogPosts.js";
import ContactUs from "views/examples/ContactUs.js";
import LandingPage from "views/examples/LandingPage.js";
import Pricing from "views/examples/Pricing.js";
import Ecommerce from "views/examples/Ecommerce.js";
import ProductPage from "views/examples/ProductPage.js";
import ProfilePage from "views/examples/ProfilePage.js";
import Error404 from "views/examples/Error404.js";
import Error500 from "views/examples/Error500.js";
import AccountSettings from "views/examples/AccountSettings.js";
import LoginPage from "views/examples/LoginPage.js";
import RegisterPage from "views/examples/RegisterPage.js";
import ResetPage from "views/examples/ResetPage.js";
import ServicesPage from "views/examples/ServicesPage.js";
import InvoicePage from "views/examples/InvoicePage.js";
import CheckoutPage from "views/examples/CheckoutPage.js";
import ChatPage from "views/examples/ChatPage.js";
import Portfolio from "views/examples/PortfolioPage.js";
import Team from "views/SectionsSections/Teams.js";

// eslint-disable-next-line
import { ApolloClient } from 'apollo-client';
// eslint-disable-next-line
import { InMemoryCache, NormalizedCacheObject } from 'apollo-cache-inmemory';
// eslint-disable-next-line
import { HttpLink } from 'apollo-link-http';
// import { gql } from "apollo-boost";
// import { useQuery } from "@apollo/react-hooks";
import { ApolloProvider } from "@apollo/react-hooks";
// eslint-disable-next-line
import gql from 'graphql-tag';

// locomotive scroll
// import LocomotiveScroll from "locomotive-scroll";

// const scroll = new LocomotiveScroll({
// el: document.querySelector('[data-scroll-container]'),
// smooth: true
// });

const cache = new InMemoryCache();
const link = new HttpLink({
  uri: 'http://localhost:4000/'
});

const client = new ApolloClient({
  cache,
  link
  // resolvers,
  // typeDefs
});

// client
//   .GET_NAVBAR({
//     query: gql`
//       query {
//         navBar {
//           brandName
//           logo
//           menuList{
//           title
//         }
//     dropDownMenuList {
//       item {
//         title
//       }
//     }
//   }
//       }
//     `
//   })
//   .then(result => console.log(result));

ReactDOM.render(
  <ApolloProvider client={client}>
    <BrowserRouter>
      <Switch>
        <Route path="/index" render={(props) => <Index {...props} />} />
        <Route path="/presentation" render={(props) => <Presentation {...props} />} />
        <Route path="/sections" render={(props) => <Sections {...props} />} />
        <Route path="/about-us" render={(props) => <AboutUs {...props} />} />
        <Route path="/blog-post/:slug" render={(props) => <BlogPost {...props} slug={props.match.params.slug}/>} />
        <Route path="/blog-posts" render={(props) => <BlogPosts {...props} />} />
        <Route path="/contact-us" render={(props) => <ContactUs {...props} />} />

        <Route path="/landing-page" render={(props) => <LandingPage {...props} />} />
        <Route path="/pricing" render={(props) => <Pricing {...props} />} />
        <Route path="/ecommerce" render={(props) => <Ecommerce {...props} />} />
        <Route path="/product-page" render={(props) => <ProductPage {...props} />} />
        <Route
          path="/profile-page/:username"
          render={(props) => <ProfilePage {...props} username={props.match.params.username} />}
        />
        <Route path="/404-error" render={(props) => <Error404 {...props} />} />
        <Route path="/500-error" render={(props) => <Error500 {...props} />} />
        <Route
          path="/account-settings"
          render={(props) => <AccountSettings {...props} />}
        />
        <Route path="/login-page" render={(props) => <LoginPage {...props} />} />
        <Route
          path="/register-page"
          render={(props) => <RegisterPage {...props} />}
        />
        <Route path="/reset-page" render={(props) => <ResetPage {...props} />} />
        <Route
          path="/invoice-page"
          render={(props) => <InvoicePage {...props} />}
        />
        <Route
          path="/checkout-page"
          render={(props) => <CheckoutPage {...props} />}
        />
        <Route path="/chat-page" render={(props) => <ChatPage {...props} />} />
        <Route path="/team" render={(props) => <Team {...props} />} />
        <Route path="/portfolio-page" render={(props) => <Portfolio {...props} />} />
        <Route path="/services-page" render={(props) => <ServicesPage {...props} />} />
        <Redirect from="/" to="/index" />
      </Switch>
    </BrowserRouter>
  </ApolloProvider>,
  document.getElementById("root")
  // scroll.on('call', func => {
  //   el: document.querySelector('[data-scroll-container]'),
  // smooth: true

  // })
);
