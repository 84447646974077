/*!

=========================================================
* BLK Design System PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/blk-design-system-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";

// reactstrap components
import {
  Badge,
  Button,
  Card,
  CardImg,
  CardHeader,
  CardBody,
  CardTitle,
  // DropdownToggle,
  // DropdownMenu,
  // DropdownItem,
  // UncontrolledDropdown,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  // Pagination,
  // PaginationItem,
  // PaginationLink,
  TabContent,
  TabPane,
  Container,
  Row,
  Col,
  UncontrolledTooltip,
} from "reactstrap";

class Projects extends React.Component {
  state = {
    activeTab: "1"
  };
  toggle = tab => {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    }
  };
  render() {
    return (
      <>
        <div className="cd-section" id="projects">
          {/* Custom Tab on plain card */}
          <Container>
            <Row>
              <Card className="card-plain">
                <Row>
                  <Col className="ml-auto mr-auto text-center mb-5" md="8">
                    <div className="space-100" />
                    <h6 className="category text-muted">Clients work</h6>
                    <h2 className="title mt-0">
                      Portfolio
                    </h2>
                  </Col>
                </Row>
                <CardHeader>
                  <div className="nav-tabs-navigation">
                    <div className="nav-tabs-wrapper">
                      <Nav tabs>
                        <NavItem>
                          <NavLink
                            className={this.state.activeTab === "1" ? "active" : ""}
                            onClick={() => {
                              this.toggle("1");
                            }}
                            style={{ cursor: 'pointer' }}
                          >
                            <i className="tim-icons icon-app" />
                            <span> Web Design {'&'} Development</span>
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            className={this.state.activeTab === "2" ? "active" : ""}
                            onClick={() => {
                              this.toggle("2");
                            }}
                            style={{ cursor: 'pointer' }}
                          >
                            <i className="tim-icons icon-palette" />
                            <span> Graphics Design</span>
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            className={this.state.activeTab === "3" ? "active" : ""}
                            onClick={() => {
                              this.toggle("3");
                            }}
                            style={{ cursor: 'pointer' }}
                          >
                            <i className="tim-icons icon-puzzle-10" />
                            <span> Logo Design</span>
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            className={this.state.activeTab === "4" ? "active" : ""}
                            onClick={() => {
                              this.toggle("4");
                            }}
                            style={{ cursor: 'pointer' }}
                          >
                            <i className="tim-icons icon-video-66" />
                            <span> Promotional Videos</span>
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            className={this.state.activeTab === "5" ? "active" : ""}
                            onClick={() => {
                              this.toggle("5");
                            }}
                            style={{ cursor: 'pointer' }}
                          >
                            <i className="tim-icons icon-support-17" />
                            <span> Troubleshoot {' & '} Support</span>
                          </NavLink>
                        </NavItem>
                      </Nav>
                    </div>
                  </div>
                </CardHeader>
                <CardBody>
                  <TabContent activeTab={this.state.activeTab} className="text-center">
                    {/* Web Design & Development */}
                    <TabPane tabId="1">
                      <h6 className="category text-muted">Portfolio/Web Design {'&'} Development </h6>
                      <div className="projects-4" style={{ paddingTop: "0" }}>
                        <Row>
                          <Col lg="4">
                            <Card
                              className="card-background"
                              style={{
                                backgroundImage:
                                  "url(" + require("assets/img/projects/thumbnail-freechoicegroup.jpg") + ")"
                              }}
                            >
                              <CardBody className="text-left">
                                <h6 className="card-category">
                                  <i className="tim-icons icon-atom" />
                                </h6>
                                <a href="#pablo" onClick={e => e.preventDefault()}>
                                  <CardTitle tag="h1">Free Choice</CardTitle>
                                </a>
                                <h6 className="desc">Australia</h6>
                                <Button
                                  color="info"
                                  href="http://www.freechoicegroup.com.au/"
                                >
                                  View Project
                                </Button>
                              </CardBody>
                            </Card>
                          </Col>
                          <Col lg="4">
                            <Card
                              className="card-background"
                              style={{
                                backgroundImage:
                                  "url(" + require("assets/img/projects/ayna1.png") + ")"
                              }}
                            >
                              <CardBody className="text-left">
                                <h6 className="card-category">
                                  <i className="tim-icons icon-tablet-2" />
                                </h6>
                                <a href="#pablo" onClick={e => e.preventDefault()}>
                                  <CardTitle tag="h1">Ayna Smart Mirror</CardTitle>
                                </a>
                                <h6 className="desc">Inhouse Project</h6>
                                <Button
                                  color="success"
                                  href="https://youtu.be/_gOFQaEuYIA"
                                >
                                  View Project
                                </Button>
                              </CardBody>
                            </Card>
                          </Col>
                          <Col lg="4">
                            <Card
                              className="card-background"
                              style={{
                                backgroundImage:
                                  "url(" + require("assets/img/bg4.jpg") + ")"
                              }}
                            >
                              <CardBody className="text-left">
                                <h6 className="card-category">
                                  <i className="tim-icons icon-html5" />
                                </h6>
                                <a href="#pablo" onClick={e => e.preventDefault()}>
                                  <CardTitle tag="h1">Project Gama</CardTitle>
                                </a>
                                <h6 className="desc">HTML code</h6>
                                <Button
                                  color="warning"
                                  href="#pablo"
                                  onClick={e => e.preventDefault()}
                                >
                                  View Project
                                </Button>
                              </CardBody>
                            </Card>
                          </Col>
                        </Row>
                      </div>
                    </TabPane>
                    {/* Graphics Design */}
                    <TabPane tabId="2">
                      <h6 className="category text-muted">Portfolio/Graphics Design </h6>
                      <div className="projects-3" style={{ paddingTop: "0" }}>
                        <Row>
                          <Col md="6">
                            <Card className="card-blog card-plain">
                              <CardHeader>
                                <h2 className="title">Hello Cycles</h2>
                                <Badge color="danger" className="mr-1">
                                  hellocycles
                                </Badge>
                                <Badge color="info">nepal</Badge>
                              </CardHeader>
                              <div className="card-image" style={{ overflow: "hidden" }}>
                                <a href="#pablo" onClick={e => e.preventDefault()}>
                                  <img
                                    data-zoomable
                                    alt="..."
                                    className="img rounded img-raised"
                                    src={require("assets/img/projects/candorPixel16.png")}
                                  />
                                </a>
                              </div>
                            </Card>
                          </Col>
                          <Col md="6">
                            <Card className="card-blog card-plain">
                              <CardHeader>
                                <h2 className="title">Canneptrade</h2>
                                <Badge color="success">webdesign</Badge>
                              </CardHeader>
                              <div className="card-image">
                                <a href="#pablo" onClick={e => e.preventDefault()}>
                                  <img
                                    alt="..."
                                    className="img rounded img-raised"
                                    src={require("assets/img/projects/canneptrade.jpg")}
                                  />
                                </a>
                              </div>
                            </Card>
                          </Col>
                          <Col md="7">
                            <Card className="card-blog card-plain">
                              <CardHeader>
                                <h2 className="title">Dr. Clean Australia</h2>
                                <Badge color="warning" className="mr-1">
                                  webdesign
                                </Badge>
                                <Badge color="primary">webhosting</Badge>
                              </CardHeader>
                              <div className="card-image">
                                <a href="#pablo" onClick={e => e.preventDefault()}>
                                  <img
                                    alt="..."
                                    className="img rounded img-raised"
                                    src={require("assets/img/projects/thumbnail-drcleanaustralia.jpg")}
                                  />
                                </a>
                              </div>
                            </Card>
                            <Card className="card-blog card-plain">
                              <CardHeader>
                                <h2 className="title">Crezona</h2>
                                <Badge color="success" className="mr-1">
                                  socialnetwork
                                </Badge>
                                <Badge color="info" className="mr-1">
                                  webdesign
                                </Badge>
                                <Badge color="warning">bootstrap</Badge>
                              </CardHeader>
                              <div className="card-image">
                                <a href="#pablo" onClick={e => e.preventDefault()}>
                                  <img
                                    alt="..."
                                    className="img rounded img-raised"
                                    src={require("assets/img/projects/crezona-coming-soon.png")}
                                  />
                                </a>
                              </div>
                            </Card>
                          </Col>
                          <Col md="5">
                            <Card className="card-blog card-plain">
                              <CardHeader>
                                <h2 className="title">Tanab Xa Zindagi</h2>
                                <Badge color="primary">flyerdesign</Badge>
                              </CardHeader>
                              <div className="card-image" style={{ height: "610px" }}>
                                <a href="#pablo" onClick={e => e.preventDefault()}>
                                  <img
                                    alt="..."
                                    className="img rounded img-raised"
                                    src={require("assets/img/projects/tanabxazindagi-event-01.png")}
                                  />
                                </a>
                              </div>
                            </Card>
                            <Card className="card-blog card-plain">
                              <div className="text-right">
                                <Badge color="info">ad</Badge>
                              </div>
                              <div className="card-image" style={{ height: "195px" }}>
                                <a href="#pablo" onClick={e => e.preventDefault()}>
                                  <img
                                    alt="..."
                                    className="img rounded img-raised"
                                    src={require("assets/img/logo-design/empresa-original-ad.png")}
                                  />
                                </a>
                              </div>
                            </Card>
                          </Col>
                          <Col md="6">

                            <Card className="card-blog card-plain">
                              <CardHeader>
                                <h2 className="title">Hello Cycles</h2>
                                <Badge color="danger" className="mr-1">
                                  hellocycles
                                </Badge>
                                <Badge color="info">nepal</Badge>
                              </CardHeader>
                              {/* <UncontrolledCarousel items={carouselItems} /> */}
                              <div className="card-image" style={{ overflow: "hidden" }}>
                                <a href="#pablo" onClick={e => e.preventDefault()}>
                                  <img
                                    alt="..."
                                    className="img rounded img-raised"
                                    src={require("assets/img/projects/helloSplash315_12_8R.png")}
                                  />
                                </a>
                              </div>
                            </Card>
                          </Col>
                          <Col md="6">
                            <Card className="card-blog card-plain">
                              <CardHeader>
                                <h2 className="title">SITS</h2>
                                <Badge color="success">graphicsdesign</Badge>
                                <Badge color="info">fifacompetition</Badge>
                              </CardHeader>
                              <div className="card-image">
                                <a href="#pablo" onClick={e => e.preventDefault()}>
                                  <img
                                    alt="..."
                                    className="img rounded img-raised"
                                    src={require("assets/img/projects/A4_Size_final_SITS.jpg")}
                                  />
                                </a>
                              </div>
                            </Card>
                          </Col>
                          <Col md="6">
                            <Card className="card-blog card-plain">
                              <CardHeader>
                                <h2 className="title">Somersby Apple Cider</h2>
                                <Badge color="success">graphicsdesign</Badge>
                                <Badge color="info">drinks</Badge>
                              </CardHeader>
                              <div className="card-image">
                                <a href="#pablo" onClick={e => e.preventDefault()}>
                                  <img
                                    alt="..."
                                    className="img rounded img-raised"
                                    src={require("assets/img/projects/ad_somersby.png")}
                                  />
                                </a>
                              </div>
                            </Card>
                          </Col>
                          <Col md="6">
                            <Card className="card-blog card-plain">
                              <CardHeader>
                                <h2 className="title">Offers section</h2>
                                <Badge color="info">webdesign</Badge>
                                <Badge color="danger">random</Badge>
                              </CardHeader>
                              <div className="card-image">
                                <a href="#pablo" onClick={e => e.preventDefault()}>
                                  <img
                                    alt="..."
                                    className="img rounded img-raised"
                                    src={require("assets/img/projects/showcase3.png")}
                                  />
                                </a>
                              </div>
                            </Card>
                          </Col>
                          <Col md="6">
                            <Card className="card-blog card-plain">
                              <CardHeader>
                                <h2 className="title">MRR Events</h2>
                                <Badge color="info">flyerdesign</Badge>
                                <Badge color="danger">events</Badge>
                              </CardHeader>
                              <div className="card-image" style={{ height: "750px" }}>
                                <a href="#pablo" onClick={e => e.preventDefault()}>
                                  <img
                                    alt="..."
                                    className="img rounded img-raised"
                                    src={require("assets/img/projects/mrr_pamphlet015_0ct17.jpg")}
                                  />
                                </a>
                              </div>
                            </Card>
                          </Col>
                          <Col md="6">
                            <Card className="card-blog card-plain">
                              <CardHeader>
                                <h2 className="title">Anzuum Parlor</h2>
                                <Badge color="info">flyerdesign</Badge>
                              </CardHeader>
                              <div className="card-image" style={{ height: "750px" }}>
                                <a href="#pablo" onClick={e => e.preventDefault()}>
                                  <img
                                    alt="..."
                                    className="img rounded img-raised"
                                    src={require("assets/img/projects/anzuum.jpg")}
                                  />
                                </a>
                              </div>
                            </Card>
                          </Col>
                          <Col md="10">
                            <Card className="card-blog card-plain">
                              <CardHeader>
                                <h2 className="title">Medimart</h2>
                                <div className="text-left">
                                  <Badge color="info">socialmedia</Badge>
                                  <Badge color="danger">fbcoverdesign</Badge>
                                </div>

                              </CardHeader>
                              <div className="card-image">
                                <a href="#pablo" onClick={e => e.preventDefault()}>
                                  <img
                                    alt="..."
                                    className="img rounded img-raised"
                                    src={require("assets/img/projects/medimart-fb-cover.jpg")}
                                  />
                                </a>
                              </div>
                            </Card>
                          </Col>
                          <Col md="2">
                            <Card className="card-blog card-plain">
                              <CardHeader>
                                <Badge color="info">ad</Badge>
                              </CardHeader>
                              <div className="card-image" style={{ height: "445px" }}>
                                <a href="#pablo" onClick={e => e.preventDefault()}>
                                  <img
                                    alt="..."
                                    className="img rounded img-raised"
                                    src={require("assets/img/logo-design/empresa-original-ad-flipped.png")}
                                  />
                                </a>
                              </div>
                            </Card>
                          </Col>
                        </Row>
                      </div>
                    </TabPane>
                    {/* Logo Design */}
                    <TabPane tabId="3">
                      <h6 className="category text-muted">Portfolio/Logo Design </h6>
                      <div className="projects-1" style={{ paddingTop: "0" }}>
                        <Navbar className="bg-transparent mb-5" expand="lg">
                          {/* <Container>
                            <div className="navbar-translate">
                              <p>52 logos found</p>
                            </div>
                            <Nav className="ml-auto" navbar>
                              <UncontrolledDropdown nav>
                                <DropdownToggle
                                  aria-expanded={false}
                                  caret
                                  color="default"
                                  data-toggle="dropdown"
                                  href="#pablo"
                                  onClick={e => e.preventDefault()}
                                  id="navbarOrder"
                                  nav
                                >
                                  <p>A-to-Z</p>
                                </DropdownToggle>
                                <DropdownMenu right>
                                  <DropdownItem
                                    href="#pablo"
                                    onClick={e => e.preventDefault()}
                                  >
                                    Z-to-A
                                  </DropdownItem>
                                  <DropdownItem
                                    href="#pablo"
                                    onClick={e => e.preventDefault()}
                                  >
                                    Newest
                                  </DropdownItem>
                                </DropdownMenu>
                              </UncontrolledDropdown>
                              <UncontrolledDropdown nav>
                                <DropdownToggle
                                  aria-expanded={false}
                                  caret
                                  color="default"
                                  data-toggle="dropdown"
                                  href="#pablo"
                                  onClick={e => e.preventDefault()}
                                  id="navbarLatest"
                                  nav
                                >
                                  <p>Latest</p>
                                </DropdownToggle>
                                <DropdownMenu right>
                                  <DropdownItem
                                    href="#pablo"
                                    onClick={e => e.preventDefault()}
                                  >
                                    Newest
                                  </DropdownItem>
                                  <DropdownItem
                                    href="#pablo"
                                    onClick={e => e.preventDefault()}
                                  >
                                    High interest
                                  </DropdownItem>
                                </DropdownMenu>
                              </UncontrolledDropdown>
                            </Nav>
                          </Container> */}
                        </Navbar>
                        <Container>
                          <Row>
                            <Col md="4">
                              <Card>
                                <CardHeader className="mt-2">
                                  <div className="float-left">
                                    <i className="tim-icons icon-tag d-inline text-info" />
                                    <p className="d-inline ml-1">#aasu</p>
                                  </div>
                                </CardHeader>
                                <CardBody className="text-center p-4">
                                  <a href="#pablo" onClick={e => e.preventDefault()}>
                                    <img
                                      alt="..."
                                      className="img-fluid rounded-circle shadow"
                                      src={require("assets/img/logo-design/aasu (3).png")}
                                      style={{ width: "150px" }}
                                    />
                                  </a>
                                  <CardTitle className="mb-0" tag="h4">
                                    Aasu
                                  </CardTitle>
                                  <p className="card-description">
                                    We are happy to work at such a great project.
                                  </p>
                                  <CardTitle className="mt-4" tag="h5">
                                    Logo Variations
                                  </CardTitle>
                                  <div className="avatar-group">
                                    <a
                                      className="avatar avatar-sm rounded-circle mr-2"
                                      href="#pablo"
                                      onClick={e => e.preventDefault()}
                                      id="tooltipaasu1"
                                    >
                                      <img
                                        alt="Clothing Store"
                                        src={require("assets/img/logo-design/aasu (1).png")}
                                      />
                                    </a>
                                    <UncontrolledTooltip
                                      placement="left"
                                      delay={0}
                                      target="tooltipaasu1"
                                    >
                                      <Card style={{ width: '10rem', height: '10rem' }}>
                                        <CardImg top src={require("assets/img/logo-design/aasu (1).png")} alt="Where Professional Meets" width="10" />
                                      </Card>
                                    </UncontrolledTooltip>
                                    <a
                                      className="avatar avatar-sm rounded-circle mr-2"
                                      href="#pablo"
                                      onClick={e => e.preventDefault()}
                                      id="tooltipaasu2"
                                    >
                                      <img
                                        alt="..."
                                        src={require("assets/img/logo-design/aasu (2).png")}
                                      />
                                    </a>
                                    <UncontrolledTooltip
                                      placement="bottom"
                                      delay={0}
                                      target="tooltipaasu2"
                                    >
                                      <Card style={{ width: '10rem', height: '10rem' }}>
                                        <CardImg top src={require("assets/img/logo-design/aasu (2).png")} alt="Restaurant and Bar" width="10" />
                                      </Card>
                                    </UncontrolledTooltip>
                                    <a
                                      className="avatar avatar-sm rounded-circle mr-2"
                                      href="#pablo"
                                      onClick={e => e.preventDefault()}
                                      id="tooltipaasu3"
                                    >
                                      <img
                                        alt="..."
                                        src={require("assets/img/logo-design/aasu (3).png")}
                                      />
                                    </a>
                                    <UncontrolledTooltip
                                      placement="right"
                                      delay={0}
                                      target="tooltipaasu3"
                                    >
                                      <Card style={{ width: '10rem', height: '10rem' }}>
                                        <CardImg top src={require("assets/img/logo-design/aasu (3).png")} alt="Clothing Store" width="10" />
                                      </Card>
                                    </UncontrolledTooltip>
                                  </div>
                                </CardBody>
                              </Card>
                            </Col>
                            <Col md="4">
                              <Card>
                                <CardHeader className="mt-2">
                                  <div className="float-left">
                                    <i className="tim-icons icon-tag d-inline text-info" />
                                    <p className="d-inline ml-1">#anzuum</p>
                                  </div>
                                </CardHeader>
                                <CardBody className="text-center p-4">
                                  <a href="#pablo" onClick={e => e.preventDefault()}>
                                    <img
                                      alt="Anzuum Logo"
                                      className="img-fluid rounded-circle shadow"
                                      src={require("assets/img/logo-design/anzuum (2).png")}
                                      style={{ width: "150px" }}
                                    />
                                  </a>
                                  <CardTitle className="mb-0" tag="h4">
                                    Anzuum
                                  </CardTitle>
                                  <p className="card-description">
                                    We are happy to work at such a great project.
                                  </p>
                                  <CardTitle className="mt-4" tag="h5">
                                    Logo Variations
                                  </CardTitle>
                                  <div className="avatar-group">
                                    <a
                                      className="avatar avatar-sm rounded-circle mr-2"
                                      href="#pablo"
                                      onClick={e => e.preventDefault()}
                                      id="tooltipanzuum1"
                                    >
                                      <img
                                        alt="Clothing Store"
                                        src={require("assets/img/logo-design/anzuum (1).png")}
                                      />
                                    </a>
                                    <UncontrolledTooltip
                                      placement="left"
                                      delay={0}
                                      target="tooltipanzuum1"
                                    >
                                      <Card style={{ width: '10rem', height: '10rem' }}>
                                        <CardImg top src={require("assets/img/logo-design/anzuum (1).png")} alt="logo sample 1" width="10" />
                                      </Card>
                                    </UncontrolledTooltip>
                                    <a
                                      className="avatar avatar-sm rounded-circle mr-2"
                                      href="#pablo"
                                      onClick={e => e.preventDefault()}
                                      id="tooltipanzuum2"
                                    >
                                      <img
                                        alt="..."
                                        src={require("assets/img/logo-design/anzuum (2).png")}
                                      />
                                    </a>
                                    <UncontrolledTooltip
                                      placement="bottom"
                                      delay={0}
                                      target="tooltipanzuum2"
                                    >
                                      <Card style={{ width: '10rem', height: '10rem' }}>
                                        <CardImg top src={require("assets/img/logo-design/anzuum (2).png")} alt="logo sample 2" width="10" />
                                      </Card>
                                    </UncontrolledTooltip>
                                    <a
                                      className="avatar avatar-sm rounded-circle mr-2"
                                      href="#pablo"
                                      onClick={e => e.preventDefault()}
                                      id="tooltipanzuum3"
                                    >
                                      <img
                                        alt="..."
                                        src={require("assets/img/logo-design/anzuum (3).png")}
                                      />
                                    </a>
                                    <UncontrolledTooltip
                                      placement="bottom"
                                      delay={0}
                                      target="tooltipanzuum3"
                                    >
                                      <Card style={{ width: '10rem', height: '10rem' }}>
                                        <CardImg top src={require("assets/img/logo-design/anzuum (3).png")} alt="logo sample 3" width="10" />
                                      </Card>
                                    </UncontrolledTooltip>
                                    <a
                                      className="avatar avatar-sm rounded-circle mr-2"
                                      href="#pablo"
                                      onClick={e => e.preventDefault()}
                                      id="tooltipanzuum4"
                                    >
                                      <img
                                        alt="Clothing Store"
                                        src={require("assets/img/logo-design/anzuum (4).png")}
                                      />
                                    </a>
                                    <UncontrolledTooltip
                                      placement="right"
                                      delay={0}
                                      target="tooltipanzuum4"
                                    >
                                      <Card style={{ width: '10rem', height: '10rem' }}>
                                        <CardImg top src={require("assets/img/logo-design/anzuum (4).png")} alt="logo sample 1" width="10" />
                                      </Card>
                                    </UncontrolledTooltip>
                                  </div>
                                </CardBody>
                              </Card>
                            </Col>
                            <Col md="4">
                              <Card>
                                <CardHeader className="mt-2">
                                  <div className="float-left">
                                    <i className="tim-icons icon-tag d-inline text-danger" />
                                    <p className="d-inline ml-1">#ayna #smartmirror</p>
                                  </div>
                                </CardHeader>
                                <CardBody className="text-center p-4">
                                  <a href="#pablo" onClick={e => e.preventDefault()}>
                                    <img
                                      alt="..."
                                      className="img-fluid rounded-circle shadow"
                                      src={require("assets/img/logo-design/ayna.png")}
                                      style={{ width: "150px" }}
                                    />
                                  </a>
                                  <CardTitle className="mb-0" tag="h4">
                                    Ayna - Smart Mirror
                                  </CardTitle>
                                  <p className="card-description">
                                    The time has come to bring our plans and ideas to
                                    life.
                                  </p>
                                  <CardTitle className="mt-4" tag="h5">
                                    Logo Variations
                                  </CardTitle>
                                  <div className="avatar-group">
                                    <a
                                      className="avatar avatar-sm rounded-circle mr-2"
                                      href="#pablo"
                                      onClick={e => e.preventDefault()}
                                      id="tooltipayna1"
                                    >
                                      <img
                                        alt="..."
                                        src={require("assets/img/logo-design/ayna.png")}
                                      />
                                    </a>
                                    <UncontrolledTooltip
                                      placement="bottom"
                                      delay={0}
                                      target="tooltipayna1"
                                    >
                                      <Card style={{ width: '10rem', height: '10rem' }}>
                                        <CardImg top src={require("assets/img/logo-design/ayna.png")} alt="logo sample 1" width="10" />
                                      </Card>
                                    </UncontrolledTooltip>
                                  </div>
                                </CardBody>
                              </Card>
                            </Col>
                            <Col md="4">
                              <Card>
                                <CardHeader className="mt-2">
                                  <div className="float-left">
                                    <i className="tim-icons icon-tag d-inline text-warning" />
                                    <p className="d-inline ml-1">#chhitosewa</p>
                                  </div>
                                </CardHeader>
                                <CardBody className="text-center p-4">
                                  <a href="#pablo" onClick={e => e.preventDefault()}>
                                    <img
                                      alt="..."
                                      className="img-fluid rounded-circle shadow"
                                      src={require("assets/img/logo-design/chhitosewa.png")}
                                      style={{ width: "150px" }}
                                    />
                                  </a>
                                  <CardTitle className="mb-0" tag="h4">
                                    Chhitosewa
                                  </CardTitle>
                                  <p className="card-description">
                                    We are developing the best design projects.
                                  </p>
                                  <CardTitle className="mt-4" tag="h5">
                                    Logo Variations
                                  </CardTitle>
                                  <div className="avatar-group">
                                    <a
                                      className="avatar avatar-sm rounded-circle mr-2"
                                      href="#pablo"
                                      onClick={e => e.preventDefault()}
                                      id="tooltipchhitosewa1"
                                    >
                                      <img
                                        alt="..."
                                        src={require("assets/img/logo-design/chhitosewa.png")}
                                      />
                                    </a>
                                    <UncontrolledTooltip
                                      placement="bottom"
                                      delay={0}
                                      target="tooltipchhitosewa1"
                                    >
                                      <Card style={{ width: '10rem', height: '10rem' }}>
                                        <CardImg top src={require("assets/img/logo-design/chhitosewa.png")} alt="logo sample 1" width="10" />
                                      </Card>
                                    </UncontrolledTooltip>
                                  </div>
                                </CardBody>
                              </Card>
                            </Col>
                            <Col md="4">
                              <Card>
                                <CardHeader className="mt-2">
                                  <div className="float-left">
                                    <i className="tim-icons icon-tag d-inline text-primary" />
                                    <p className="d-inline ml-1">#civilnepal</p>
                                  </div>

                                </CardHeader>
                                <CardBody className="text-center p-4">
                                  <a href="#pablo" onClick={e => e.preventDefault()}>
                                    <img
                                      alt="..."
                                      className="img-fluid rounded-circle shadow"
                                      src={require("assets/img/logo-design/civilnepal.png")}
                                      style={{ width: "150px" }}
                                    />
                                  </a>
                                  <CardTitle className="mb-0" tag="h4">
                                    Civil Nepal Engineering
                                  </CardTitle>
                                  <p className="card-description">
                                    It is important to save every project safely with our
                                    app.
                                  </p>
                                  <CardTitle className="mt-4" tag="h5">
                                    Logo Variations
                                  </CardTitle>
                                  <div className="avatar-group">
                                    <a
                                      className="avatar avatar-sm rounded-circle mr-2"
                                      href="#pablo"
                                      onClick={e => e.preventDefault()}
                                      id="tooltipcivilnepal1"
                                    >
                                      <img
                                        alt="..."
                                        src={require("assets/img/logo-design/civilnepal.png")}
                                      />
                                    </a>
                                    <UncontrolledTooltip
                                      placement="bottom"
                                      delay={0}
                                      target="tooltipcivilnepal1"
                                    >
                                      <Card style={{ width: '10rem', height: '10rem' }}>
                                        <CardImg top src={require("assets/img/logo-design/chhitosewa.png")} alt="logo sample 1" width="10" />
                                      </Card>
                                    </UncontrolledTooltip>
                                  </div>
                                </CardBody>
                              </Card>
                            </Col>
                            <Col md="4">
                              <Card>
                                <CardHeader className="mt-2">
                                  <div className="float-left">
                                    <i className="tim-icons icon-tag d-inline" />
                                    <p className="d-inline ml-1">#glomeworks</p>
                                  </div>
                                </CardHeader>
                                <CardBody className="text-center p-4">
                                  <a href="#pablo" onClick={e => e.preventDefault()}>
                                    <img
                                      alt="..."
                                      className="img-fluid rounded-circle shadow"
                                      src={require("assets/img/logo-design/glomeworks.png")}
                                      style={{ width: "150px" }}
                                    />
                                  </a>
                                  <CardTitle className="mb-0" tag="h4">
                                    Glomeworks
                                  </CardTitle>
                                  <p className="card-description">
                                    Here you can add your description and bellow add your
                                    members.
                                  </p>
                                  <CardTitle className="mt-4" tag="h5">
                                    Logo Variations
                                  </CardTitle>
                                  <div className="avatar-group">
                                    <a
                                      className="avatar avatar-sm rounded-circle"
                                      href="#pablo"
                                      onClick={e => e.preventDefault()}
                                      id="tooltipglomeworks1"
                                    >
                                      <img
                                        alt="..."
                                        src={require("assets/img/logo-design/glomeworks.png")}
                                      />
                                    </a>
                                    <UncontrolledTooltip
                                      placement="bottom"
                                      delay={0}
                                      target="tooltipglomeworks1"
                                    >
                                      <Card style={{ width: '10rem', height: '10rem' }}>
                                        <CardImg top src={require("assets/img/logo-design/glomeworks.png")} alt="logo sample 1" width="10" />
                                      </Card>
                                    </UncontrolledTooltip>
                                  </div>
                                </CardBody>
                              </Card>
                            </Col>
                            <Col md="4">
                              <Card>
                                <CardHeader className="mt-2">
                                  <div className="float-left">
                                    <i className="tim-icons icon-tag d-inline text-primary" />
                                    <p className="d-inline ml-1">#hocnepal</p>
                                  </div>

                                </CardHeader>
                                <CardBody className="text-center p-4">
                                  <a href="#pablo" onClick={e => e.preventDefault()}>
                                    <img
                                      alt="..."
                                      className="img-fluid rounded-circle shadow"
                                      src={require("assets/img/logo-design/hocnepal.png")}
                                      style={{ width: "150px" }}
                                    />
                                  </a>
                                  <CardTitle className="mb-0" tag="h4">
                                    HOC Nepal
                                  </CardTitle>
                                  <p className="card-description">
                                    It is important to save every project safely with our
                                    app.
                                  </p>
                                  <CardTitle className="mt-4" tag="h5">
                                    Logo Variations
                                  </CardTitle>
                                  <div className="avatar-group">
                                    <a
                                      className="avatar avatar-sm rounded-circle mr-2"
                                      href="#pablo"
                                      onClick={e => e.preventDefault()}
                                      id="tooltiphocnepal1"
                                    >
                                      <img
                                        alt="..."
                                        src={require("assets/img/logo-design/hocnepal.png")}
                                      />
                                    </a>
                                    <UncontrolledTooltip
                                      placement="bottom"
                                      delay={0}
                                      target="tooltiphocnepal1"
                                    >
                                      <Card style={{ width: '10rem', height: '10rem' }}>
                                        <CardImg top src={require("assets/img/logo-design/hocnepal.png")} alt="logo sample 1" width="10" />
                                      </Card>
                                    </UncontrolledTooltip>
                                  </div>
                                </CardBody>
                              </Card>
                            </Col>
                            <Col md="4">
                              <Card>
                                <CardHeader className="mt-2">
                                  <div className="float-left">
                                    <i className="tim-icons icon-tag d-inline" />
                                    <p className="d-inline ml-1">#medimart</p>
                                  </div>
                                </CardHeader>
                                <CardBody className="text-center p-4">
                                  <a href="#pablo" onClick={e => e.preventDefault()}>
                                    <img
                                      alt="..."
                                      className="img-fluid rounded-circle shadow"
                                      src={require("assets/img/logo-design/medimart.png")}
                                      style={{ width: "150px" }}
                                    />
                                  </a>
                                  <CardTitle className="mb-0" tag="h4">
                                    Medimart
                                  </CardTitle>
                                  <p className="card-description">
                                    Here you can add your description and bellow add your
                                    members.
                                  </p>
                                  <CardTitle className="mt-4" tag="h5">
                                    Logo Variations
                                  </CardTitle>
                                  <div className="avatar-group">
                                    <a
                                      className="avatar avatar-sm rounded-circle"
                                      href="#pablo"
                                      onClick={e => e.preventDefault()}
                                      id="tooltipmedimart1"
                                    >
                                      <img
                                        alt="..."
                                        src={require("assets/img/logo-design/medimart.png")}
                                      />
                                    </a>
                                    <UncontrolledTooltip
                                      placement="bottom"
                                      delay={0}
                                      target="tooltipmedimart1"
                                    >
                                      <Card style={{ width: '10rem', height: '10rem' }}>
                                        <CardImg top src={require("assets/img/logo-design/medimart.png")} alt="logo sample 1" width="10" />
                                      </Card>
                                    </UncontrolledTooltip>
                                  </div>
                                </CardBody>
                              </Card>
                            </Col>
                            <Col md="4">
                              <Card>
                                <CardHeader className="mt-2">
                                  <div className="float-left">
                                    <i className="tim-icons icon-tag d-inline text-primary" />
                                    <p className="d-inline ml-1">#meropathsala</p>
                                  </div>

                                </CardHeader>
                                <CardBody className="text-center p-4">
                                  <a href="#pablo" onClick={e => e.preventDefault()}>
                                    <img
                                      alt="..."
                                      className="img-fluid rounded-circle shadow"
                                      src={require("assets/img/logo-design/meropathsala.png")}
                                      style={{ width: "150px" }}
                                    />
                                  </a>
                                  <CardTitle className="mb-0" tag="h4">
                                    Meropathsala
                                  </CardTitle>
                                  <p className="card-description">
                                    It is important to save every project safely with our
                                    app.
                                  </p>
                                  <CardTitle className="mt-4" tag="h5">
                                    Logo Variations
                                  </CardTitle>
                                  <div className="avatar-group">
                                    <a
                                      className="avatar avatar-sm rounded-circle mr-2"
                                      href="#pablo"
                                      onClick={e => e.preventDefault()}
                                      id="tooltipmeropathsala1"
                                    >
                                      <img
                                        alt="..."
                                        src={require("assets/img/logo-design/meropathsala.png")}
                                      />
                                    </a>
                                    <UncontrolledTooltip
                                      placement="bottom"
                                      delay={0}
                                      target="tooltipmeropathsala1"
                                    >
                                      <Card style={{ width: '10rem', height: '10rem' }}>
                                        <CardImg top src={require("assets/img/logo-design/meropathsala.png")} alt="logo sample 1" width="10" />
                                      </Card>
                                    </UncontrolledTooltip>
                                  </div>
                                </CardBody>
                              </Card>
                            </Col>
                            <Col md="4">
                              <Card>
                                <CardHeader className="mt-2">
                                  <div className="float-left">
                                    <i className="tim-icons icon-tag d-inline" />
                                    <p className="d-inline ml-1">#mythological</p>
                                  </div>
                                </CardHeader>
                                <CardBody className="text-center p-4">
                                  <a href="#pablo" onClick={e => e.preventDefault()}>
                                    <img
                                      alt="..."
                                      className="img-fluid rounded-circle shadow"
                                      src={require("assets/img/logo-design/mythological.png")}
                                      style={{ width: "150px" }}
                                    />
                                  </a>
                                  <CardTitle className="mb-0" tag="h4">
                                    mythological
                                  </CardTitle>
                                  <p className="card-description">
                                    Here you can add your description and bellow add your
                                    members.
                                  </p>
                                  <CardTitle className="mt-4" tag="h5">
                                    Logo Variations
                                  </CardTitle>
                                  <div className="avatar-group">
                                    <a
                                      className="avatar avatar-sm rounded-circle"
                                      href="#pablo"
                                      onClick={e => e.preventDefault()}
                                      id="tooltipmythological1"
                                    >
                                      <img
                                        alt="..."
                                        src={require("assets/img/logo-design/mythological.png")}
                                      />
                                    </a>
                                    <UncontrolledTooltip
                                      placement="bottom"
                                      delay={0}
                                      target="tooltipmythological1"
                                    >
                                      <Card style={{ width: '10rem', height: '10rem' }}>
                                        <CardImg top src={require("assets/img/logo-design/mythological.png")} alt="logo sample 1" width="10" />
                                      </Card>
                                    </UncontrolledTooltip>
                                  </div>
                                </CardBody>
                              </Card>
                            </Col>
                            <Col md="4">
                              <Card>
                                <CardHeader className="mt-2">
                                  <div className="float-left">
                                    <i className="tim-icons icon-tag d-inline text-primary" />
                                    <p className="d-inline ml-1">#perfectwedding</p>
                                  </div>

                                </CardHeader>
                                <CardBody className="text-center p-4">
                                  <a href="#pablo" onClick={e => e.preventDefault()}>
                                    <img
                                      alt="..."
                                      className="img-fluid rounded-circle shadow"
                                      src={require("assets/img/logo-design/perfectwedding.png")}
                                      style={{ width: "150px" }}
                                    />
                                  </a>
                                  <CardTitle className="mb-0" tag="h4">
                                    Perfect Wedding {"&"} Events
                                  </CardTitle>
                                  <p className="card-description">
                                    It is important to save every project safely with our
                                    app.
                                  </p>
                                  <CardTitle className="mt-4" tag="h5">
                                    Logo Variations
                                  </CardTitle>
                                  <div className="avatar-group">
                                    <a
                                      className="avatar avatar-sm rounded-circle mr-2"
                                      href="#pablo"
                                      onClick={e => e.preventDefault()}
                                      id="tooltipperfectwedding1"
                                    >
                                      <img
                                        alt="..."
                                        src={require("assets/img/logo-design/perfectwedding.png")}
                                      />
                                    </a>
                                    <UncontrolledTooltip
                                      placement="bottom"
                                      delay={0}
                                      target="tooltipperfectwedding1"
                                    >
                                      <Card style={{ width: '10rem', height: '10rem' }}>
                                        <CardImg top src={require("assets/img/logo-design/perfectwedding.png")} alt="logo sample 1" width="10" />
                                      </Card>
                                    </UncontrolledTooltip>
                                  </div>
                                </CardBody>
                              </Card>
                            </Col>
                            <Col md="4">
                              <Card>
                                <CardHeader className="mt-2">
                                  <div className="float-left">
                                    <i className="tim-icons icon-tag d-inline" />
                                    <p className="d-inline ml-1">#propheticdeclarations</p>
                                  </div>
                                </CardHeader>
                                <CardBody className="text-center p-4">
                                  <a href="#pablo" onClick={e => e.preventDefault()}>
                                    <img
                                      alt="..."
                                      className="img-fluid rounded-circle shadow"
                                      src={require("assets/img/logo-design/propheticdeclarations.png")}
                                      style={{ width: "150px" }}
                                    />
                                  </a>
                                  <CardTitle className="mb-0" tag="h4">
                                    Prophetic Declarations
                                  </CardTitle>
                                  <p className="card-description">
                                    Here you can add your description and bellow add your
                                    members.
                                  </p>
                                  <CardTitle className="mt-4" tag="h5">
                                    Logo Variations
                                  </CardTitle>
                                  <div className="avatar-group">
                                    <a
                                      className="avatar avatar-sm rounded-circle"
                                      href="#pablo"
                                      onClick={e => e.preventDefault()}
                                      id="tooltippropheticdeclarations1"
                                    >
                                      <img
                                        alt="..."
                                        src={require("assets/img/logo-design/propheticdeclarations.png")}
                                      />
                                    </a>
                                    <UncontrolledTooltip
                                      placement="bottom"
                                      delay={0}
                                      target="tooltippropheticdeclarations1"
                                    >
                                      <Card style={{ width: '10rem', height: '10rem' }}>
                                        <CardImg top src={require("assets/img/logo-design/propheticdeclarations.png")} alt="logo sample 1" width="10" />
                                      </Card>
                                    </UncontrolledTooltip>
                                  </div>
                                </CardBody>
                              </Card>
                            </Col>
                            <Col md="4">
                              <Card>
                                <CardHeader className="mt-2">
                                  <div className="float-left">
                                    <i className="tim-icons icon-tag d-inline text-primary" />
                                    <p className="d-inline ml-1">#sktm</p>
                                  </div>

                                </CardHeader>
                                <CardBody className="text-center p-4">
                                  <a href="#pablo" onClick={e => e.preventDefault()}>
                                    <img
                                      alt="..."
                                      className="img-fluid rounded-circle shadow"
                                      src={require("assets/img/logo-design/sktm.png")}
                                      style={{ width: "150px" }}
                                    />
                                  </a>
                                  <CardTitle className="mb-0" tag="h4">
                                    Surkhet Musical Band
                                  </CardTitle>
                                  <p className="card-description">
                                    It is important to save every project safely with our
                                    app.
                                  </p>
                                  <CardTitle className="mt-4" tag="h5">
                                    Logo Variations
                                  </CardTitle>
                                  <div className="avatar-group">
                                    <a
                                      className="avatar avatar-sm rounded-circle mr-2"
                                      href="#pablo"
                                      onClick={e => e.preventDefault()}
                                      id="tooltipsktm1"
                                    >
                                      <img
                                        alt="..."
                                        src={require("assets/img/logo-design/sktm.png")}
                                      />
                                    </a>
                                    <UncontrolledTooltip
                                      placement="bottom"
                                      delay={0}
                                      target="tooltipsktm1"
                                    >
                                      <Card style={{ width: '10rem', height: '10rem' }}>
                                        <CardImg top src={require("assets/img/logo-design/sktm.png")} alt="logo sample 1" width="10" />
                                      </Card>
                                    </UncontrolledTooltip>
                                  </div>
                                </CardBody>
                              </Card>
                            </Col>
                            <Col md="4">
                              <Card>
                                <CardHeader className="mt-2">
                                  <div className="float-left">
                                    <i className="tim-icons icon-tag d-inline" />
                                    <p className="d-inline ml-1">#tanabxazindagi</p>
                                  </div>
                                </CardHeader>
                                <CardBody className="text-center p-4">
                                  <a href="#pablo" onClick={e => e.preventDefault()}>
                                    <img
                                      alt="..."
                                      className="img-fluid rounded-circle shadow"
                                      src={require("assets/img/logo-design/tanabxazindagi.png")}
                                      style={{ width: "150px" }}
                                    />
                                  </a>
                                  <CardTitle className="mb-0" tag="h4">
                                    Tanab Xa Zindagi
                                  </CardTitle>
                                  <p className="card-description">
                                    Here you can add your description and bellow add your
                                    members.
                                  </p>
                                  <CardTitle className="mt-4" tag="h5">
                                    Logo Variations
                                  </CardTitle>
                                  <div className="avatar-group">
                                    <a
                                      className="avatar avatar-sm rounded-circle"
                                      href="#pablo"
                                      onClick={e => e.preventDefault()}
                                      id="tooltiptanabxazindagi1"
                                    >
                                      <img
                                        alt="..."
                                        src={require("assets/img/logo-design/tanabxazindagi.png")}
                                      />
                                    </a>
                                    <UncontrolledTooltip
                                      placement="bottom"
                                      delay={0}
                                      target="tooltiptanabxazindagi1"
                                    >
                                      <Card style={{ width: '10rem', height: '10rem' }}>
                                        <CardImg top src={require("assets/img/logo-design/tanabxazindagi.png")} alt="logo sample 1" width="10" />
                                      </Card>
                                    </UncontrolledTooltip>
                                  </div>
                                </CardBody>
                              </Card>
                            </Col>
                            <Col md="4">
                              <Card>
                                <CardHeader className="mt-2">
                                  <div className="float-left">
                                    <i className="tim-icons icon-tag d-inline text-primary" />
                                    <p className="d-inline ml-1">#themovementdanceacademy</p>
                                  </div>

                                </CardHeader>
                                <CardBody className="text-center p-4">
                                  <a href="#pablo" onClick={e => e.preventDefault()}>
                                    <img
                                      alt="..."
                                      className="img-fluid rounded-circle shadow"
                                      src={require("assets/img/logo-design/themovementdanceacademy.png")}
                                      style={{ width: "150px" }}
                                    />
                                  </a>
                                  <CardTitle className="mb-0" tag="h4">
                                    The Movement Dance Academy
                                  </CardTitle>
                                  <p className="card-description">
                                    It is important to save every project safely with our
                                    app.
                                  </p>
                                  <CardTitle className="mt-4" tag="h5">
                                    Logo Variations
                                  </CardTitle>
                                  <div className="avatar-group">
                                    <a
                                      className="avatar avatar-sm rounded-circle mr-2"
                                      href="#pablo"
                                      onClick={e => e.preventDefault()}
                                      id="tooltipthemovementdanceacademy1"
                                    >
                                      <img
                                        alt="..."
                                        src={require("assets/img/logo-design/themovementdanceacademy.png")}
                                      />
                                    </a>
                                    <UncontrolledTooltip
                                      placement="bottom"
                                      delay={0}
                                      target="tooltipthemovementdanceacademy1"
                                    >
                                      <Card style={{ width: '10rem', height: '10rem' }}>
                                        <CardImg top src={require("assets/img/logo-design/themovementdanceacademy.png")} alt="logo sample 1" width="10" />
                                      </Card>
                                    </UncontrolledTooltip>
                                  </div>
                                </CardBody>
                              </Card>
                            </Col>
                            <Col md="4">
                              <Card>
                                <CardHeader className="mt-2">
                                  <div className="float-left">
                                    <i className="tim-icons icon-tag d-inline" />
                                    <p className="d-inline ml-1">#thezappersband</p>
                                  </div>
                                </CardHeader>
                                <CardBody className="text-center p-4">
                                  <a href="#pablo" onClick={e => e.preventDefault()}>
                                    <img
                                      alt="..."
                                      className="img-fluid rounded-circle shadow"
                                      src={require("assets/img/logo-design/thezappersband.png")}
                                      style={{ width: "150px" }}
                                    />
                                  </a>
                                  <CardTitle className="mb-0" tag="h4">
                                    The Zappers Band
                                  </CardTitle>
                                  <p className="card-description">
                                    Here you can add your description and bellow add your
                                    members.
                                  </p>
                                  <CardTitle className="mt-4" tag="h5">
                                    Logo Variations
                                  </CardTitle>
                                  <div className="avatar-group">
                                    <a
                                      className="avatar avatar-sm rounded-circle"
                                      href="#pablo"
                                      onClick={e => e.preventDefault()}
                                      id="tooltipthezappersband1"
                                    >
                                      <img
                                        alt="..."
                                        src={require("assets/img/logo-design/thezappersband.png")}
                                      />
                                    </a>
                                    <UncontrolledTooltip
                                      placement="bottom"
                                      delay={0}
                                      target="tooltipthezappersband1"
                                    >
                                      <Card style={{ width: '10rem', height: '10rem' }}>
                                        <CardImg top src={require("assets/img/logo-design/thezappersband.png")} alt="logo sample 1" width="10" />
                                      </Card>
                                    </UncontrolledTooltip>
                                  </div>
                                </CardBody>
                              </Card>
                            </Col>
                            <Col md="4">
                              <Card>
                                <CardHeader className="mt-2">
                                  <div className="float-left">
                                    <i className="tim-icons icon-tag d-inline text-primary" />
                                    <p className="d-inline ml-1">#twintees</p>
                                  </div>

                                </CardHeader>
                                <CardBody className="text-center p-4">
                                  <a href="#pablo" onClick={e => e.preventDefault()}>
                                    <img
                                      alt="..."
                                      className="img-fluid rounded-circle shadow"
                                      src={require("assets/img/logo-design/twintees.png")}
                                      style={{ width: "150px" }}
                                    />
                                  </a>
                                  <CardTitle className="mb-0" tag="h4">
                                    Twintees
                                  </CardTitle>
                                  <p className="card-description">
                                    It is important to save every project safely with our
                                    app.
                                  </p>
                                  <CardTitle className="mt-4" tag="h5">
                                    Logo Variations
                                  </CardTitle>
                                  <div className="avatar-group">
                                    <a
                                      className="avatar avatar-sm rounded-circle mr-2"
                                      href="#pablo"
                                      onClick={e => e.preventDefault()}
                                      id="tooltiptwintees1"
                                    >
                                      <img
                                        alt="..."
                                        src={require("assets/img/logo-design/twintees.png")}
                                      />
                                    </a>
                                    <UncontrolledTooltip
                                      placement="bottom"
                                      delay={0}
                                      target="tooltiptwintees1"
                                    >
                                      <Card style={{ width: '10rem', height: '10rem' }}>
                                        <CardImg top src={require("assets/img/logo-design/twintees.png")} alt="logo sample 1" width="10" />
                                      </Card>
                                    </UncontrolledTooltip>
                                  </div>
                                </CardBody>
                              </Card>
                            </Col>
                            <Col md="4">
                              <Card>
                                <CardHeader className="mt-2">
                                  <div className="float-left">
                                    <i className="tim-icons icon-tag d-inline" />
                                    <p className="d-inline ml-1">#vibenwave</p>
                                  </div>
                                </CardHeader>
                                <CardBody className="text-center p-4">
                                  <a href="#pablo" onClick={e => e.preventDefault()}>
                                    <img
                                      alt="..."
                                      className="img-fluid rounded-circle shadow"
                                      src={require("assets/img/logo-design/vibenwave.png")}
                                      style={{ width: "150px" }}
                                    />
                                  </a>
                                  <CardTitle className="mb-0" tag="h4">
                                    Vibe {"&"} Wave Dance
                                  </CardTitle>
                                  <p className="card-description">
                                    Here you can add your description and bellow add your
                                    members.
                                  </p>
                                  <CardTitle className="mt-4" tag="h5">
                                    Logo Variations
                                  </CardTitle>
                                  <div className="avatar-group">
                                    <a
                                      className="avatar avatar-sm rounded-circle"
                                      href="#pablo"
                                      onClick={e => e.preventDefault()}
                                      id="tooltipvibenwave1"
                                    >
                                      <img
                                        alt="..."
                                        src={require("assets/img/logo-design/vibenwave.png")}
                                      />
                                    </a>
                                    <UncontrolledTooltip
                                      placement="bottom"
                                      delay={0}
                                      target="tooltipvibenwave1"
                                    >
                                      <Card style={{ width: '10rem', height: '10rem' }}>
                                        <CardImg top src={require("assets/img/logo-design/vibenwave.png")} alt="logo sample 1" width="10" />
                                      </Card>
                                    </UncontrolledTooltip>
                                  </div>
                                </CardBody>
                              </Card>
                            </Col>
                          </Row>
                          { /* <footer className="mt-5">
                            {/* TODO: 
                              Need to code for working pagination in which in one page should have six items
                            
                            <Pagination
                              className="pagination float-left"
                              listClassName="float-left"
                            >
                              <PaginationItem>
                                <PaginationLink
                                  aria-label="Previous"
                                  href="#pablo"
                                  onClick={e => e.preventDefault()}
                                >
                                  <span aria-hidden={true}>
                                    <i
                                      aria-hidden={true}
                                      className="tim-icons icon-double-left"
                                    />
                                  </span>
                                </PaginationLink>
                              </PaginationItem>
                              <PaginationItem className="active">
                                <PaginationLink
                                  href="#pablo"
                                  onClick={e => e.preventDefault()}
                                >
                                  1
                                </PaginationLink>
                              </PaginationItem>
                              <PaginationItem>
                                <PaginationLink
                                  href="#pablo"
                                  onClick={e => e.preventDefault()}
                                >
                                  2
                                </PaginationLink>
                              </PaginationItem>
                              <PaginationItem>
                                <PaginationLink
                                  href="#pablo"
                                  onClick={e => e.preventDefault()}
                                >
                                  ...
                                </PaginationLink>
                              </PaginationItem>
                              <PaginationItem>
                                <PaginationLink
                                  href="#pablo"
                                  onClick={e => e.preventDefault()}
                                >
                                  6
                                </PaginationLink>
                              </PaginationItem>
                              <PaginationItem>
                                <PaginationLink
                                  aria-label="Next"
                                  href="#pablo"
                                  onClick={e => e.preventDefault()}
                                >
                                  <span aria-hidden={true}>
                                    <i
                                      aria-hidden={true}
                                      className="tim-icons icon-double-right"
                                    />
                                  </span>
                                </PaginationLink>
                              </PaginationItem>
                            </Pagination>
                            <div className="text-right">
                              <p>Showing 6 out of 36</p>
                              <div />
                            </div>
                          </footer> */}
                        </Container>
                      </div>
                    </TabPane>
                    {/* Promotional Videos */}
                    <TabPane tabId="4">
                      <div className="col-md-12"><h1 className="title">500</h1><h2 className="description">Error :(</h2><h4 className="description">We are sorry. This was unexpected.</h4></div>
                    </TabPane>
                    {/* Troubleshoot & Support */}
                    <TabPane tabId="5">
                      <div className="col-md-12"><h1 className="title">500</h1><h2 className="description">Error :(</h2><h4 className="description">We are sorry. This was unexpected.</h4></div>
                    </TabPane>
                  </TabContent>
                </CardBody>
              </Card>
            </Row>
          </Container>

          {/* END Custom Tab on plain card */}

        </div>{" "}
      </>
    );
  }
}

export default Projects;
