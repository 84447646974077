import React from "react";
  
// core components
import ColorNavbar from "components/Navbars/ColorNavbar.js";
import DemoFooter from "components/Footers/DemoFooter.js";
import Projects from "views/SectionsSections/Projects.js";

class PortfolioPage extends React.Component {
    componentDidMount() {
        document.body.classList.add("index-page");
        document.documentElement.scrollTop = 0;
        document.scrollingElement.scrollTop = 0;
        this.refs.wrapper.scrollTop = 0;
    }
    componentWillUnmount() {
        document.body.classList.remove("index-page");
    }
    render() {
        return (
            <div className="">
                <ColorNavbar />
                <div className="wrapper" ref="wrapper">
                    
                    <div className="main" >
                    <Projects />
                        
                    </div>
                    <DemoFooter />
                </div>
            </div>
        );
    }
}

export default PortfolioPage;