import React from "react";
// react plugin used to create charts

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardTitle,
  Container,
  Row,
  Col,
} from "reactstrap";

import gql from "graphql-tag";
import { graphql } from "react-apollo";

// core components
import ColorNavbar from "components/Navbars/ColorNavbar.js";
import DemoFooter from "components/Footers/DemoFooter.js";

import { NavItem, NavLink, Nav, TabContent, TabPane } from "reactstrap";
import { Link } from "react-router-dom";

const GET_SERVICES_SERVICE_CATLOG = gql`
  {
    contenSection {
      title
      item {
        icon
        title
      }
    }
  }
`;

class ServicesPage extends React.Component {
  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.wrapper.scrollTop = 0;
    document.body.classList.add("landing-page");
  }
  componentWillUnmount() {
    document.body.classList.remove("landing-page");
  }

  state = {
    activeTab: "1",
    priceTab: "1"
  };

  toggle = (tab) => {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  };

  state = {
    firstTabs: "1",
    secondTabs: "2",
    thirdTabs: "1",
  };
  priceToggle = (tabName, tab) => {
    if (this.state.priceTab !== tab) {
      this.setState({
        [tabName]: tab,
      });
    }
  };

  render() {
    return (
      <>
        <ColorNavbar />
        <div className="wrapper" ref="wrapper">
          <div className="main">
            <div className="space-100">

              {/* ********* HEADER 5 ********* */}
              <div className="header header-5" style={{ height: "auto" }}>
                <div className="page-header">
                  <Container style={{ paddingTop: "15vh" }}>
                    <Row className="align-items-center mt-md-5">
                      <Col className="ml-auto mr-auto" lg="6" md="8">
                        <h1 className="text-white">
                          We make your dream <br />
                          alive!
                        </h1>
                        <span className="lead text-white mb-3">
                          <blockquote className="blockquote">
                            “Design is everywhere. From the dress you’re wearing to
                            the Smartphone you’re holding, it’s design.”
                            <br />
                            <small>Samadara Ginige, Designer and Developer</small>
                          </blockquote>
                        </span>
                        <div
                          className="btn-wrapper mb-3"
                          style={{ display: "flex", alignItems: "center" }}
                        >
                          <Button
                            className="btn-link"
                            color="success"
                            href="#pablo"
                            onClick={(e) => e.preventDefault()}
                            size="sm"
                          >
                            <i className="tim-icons icon-minimal-right" />
                          </Button>
                          <p
                            className="category text-success"
                            style={{ margin: "0px" }}
                          >
                            Get Started
                          </p>
                        </div>
                        <div className="btn-wrapper">
                          <div className="button-container">
                            <Button
                              className="btn-icon btn-simple btn-round mr-1"
                              color="neutral"
                              href="#pablo"
                              onClick={(e) => e.preventDefault()}
                            >
                              <i className="fab fa-twitter" />
                            </Button>
                            <Button
                              className="btn-icon btn-simple btn-round mr-1"
                              color="neutral"
                              href="#pablo"
                              onClick={(e) => e.preventDefault()}
                            >
                              <i className="fab fa-dribbble" />
                            </Button>
                            <Button
                              className="btn-icon btn-simple btn-round"
                              color="neutral"
                              href="#pablo"
                              onClick={(e) => e.preventDefault()}
                            >
                              <i className="fab fa-facebook" />
                            </Button>
                          </div>
                        </div>
                      </Col>
                      <Col className="ml-auto text-center" lg="5">
                        <div className="phone-container">
                          <img
                            alt="..."
                            className="phone"
                            src={require("assets/img/iphone3.png")}
                          />
                        </div>
                        <img
                          alt="..."
                          className="shape"
                          src={require("assets/img/patrat.png")}
                        />
                        <img
                          alt="..."
                          className="shape2"
                          src={require("assets/img/cercuri.png")}
                        />
                        <img
                          alt="..."
                          className="shape3"
                          src={require("assets/img/waves.png")}
                        />
                      </Col>
                    </Row>
                  </Container>
                </div>
              </div>
              {/* ********* END HEADER 5 ********* */}

              <section className="section section-lg pt-0">
                <section className="section" style={{ padding: "0px" }}>
                  <img
                    alt="..."
                    className="path"
                    src={require("assets/img/path4.png")}
                  />

                  {/* ********* PROJECTS 4 ********* */}
                  <div className="projects-4 pt-0">
                    <Container>
                      <Row>
                        <Col className="" md="8">
                          <hr className="line-success" />
                          <h6 className="category text-muted">PROJECTS</h6>
                          <h2 className="title mt-0">Best Projects</h2>
                        </Col>
                      </Row>
                      <div className="space-50" />
                      <Row>
                        <Col lg="4">
                          <Card
                            className="card-background"
                            style={{
                              backgroundImage:
                                "url(" +
                                require("assets/img/projects/ayna1.png") +
                                ")",
                            }}
                          >
                            <CardBody
                              className="text-left"
                              style={{ padding: "8px" }}
                            >
                              <h6 className="card-category">
                                <i className="tim-icons icon-atom" />
                              </h6>
                              <a href="#pablo" onClick={(e) => e.preventDefault()}>
                                <CardTitle tag="h1">Ayna Smart Mirror</CardTitle>
                              </a>
                              <h6 className="desc">IoT, In-house Project</h6>
                              <Button
                                color="info"
                                href="#pablo"
                                onClick={(e) => e.preventDefault()}
                              >
                                View Details
                              </Button>
                            </CardBody>
                          </Card>
                        </Col>
                        <Col lg="4">
                          <Card
                            className="card-background"
                            style={{
                              backgroundImage:
                                "url(" + require("assets/img/bg7.jpg") + ")",
                            }}
                          >
                            <CardBody
                              className="text-left"
                              style={{ padding: "8px" }}
                            >
                              <h6 className="card-category">
                                <i className="tim-icons icon-app" />
                              </h6>
                              <a href="#pablo" onClick={(e) => e.preventDefault()}>
                                <CardTitle tag="h1">FreeChoice Group</CardTitle>
                              </a>
                              <h6 className="desc">Web Development, Australia</h6>
                              <Button
                                color="success"
                                href="#pablo"
                                onClick={(e) => e.preventDefault()}
                              >
                                View Details
                              </Button>
                            </CardBody>
                          </Card>
                        </Col>
                        <Col lg="4">
                          <Card
                            className="card-background"
                            style={{
                              backgroundImage:
                                "url(" + require("assets/img/bg4.jpg") + ")",
                            }}
                          >
                            <CardBody
                              className="text-left"
                              style={{ padding: "8px" }}
                            >
                              <h6 className="card-category">
                                <i className="tim-icons icon-mobile" />
                              </h6>
                              <a href="#pablo" onClick={(e) => e.preventDefault()}>
                                <CardTitle tag="h1">UN Digital Library</CardTitle>
                              </a>
                              <h6 className="desc">Intern Project, Nepal</h6>
                              <Button
                                color="warning"
                                href="#pablo"
                                onClick={(e) => e.preventDefault()}
                              >
                                View Details
                              </Button>
                            </CardBody>
                          </Card>
                        </Col>
                      </Row>
                    </Container>
                  </div>
                  {/* ********* END PROJECTS 4 ********* */}
                  {/* ********* PROJECTS 2 ********* */}
                  <div className="projects-2 project-raised">
                    <Container>
                      <Row>
                        <Col className="" md="8" style={{ marginBottom: "50px" }}>
                          <hr className="line-success" />
                          <h6 className="category text-muted">Services</h6>
                          <h2 className="title mt-0">Our services</h2>
                        </Col>
                      </Row>
                      <Nav
                        className="nav-pills-info nav-pills-icons nav-pills-lg"
                        pills
                        role="tablist"
                      >
                        <NavItem className="m-auto">
                          <NavLink
                            className={this.state.activeTab === "1" ? "active" : ""}
                            onClick={() => {
                              this.toggle("1");
                            }}
                            style={{ cursor: "pointer" }}
                          >
                            <i className="tim-icons icon-app" />
                            Web Design {"&"} Development
                          </NavLink>
                        </NavItem>
                        <NavItem className="m-auto">
                          <NavLink
                            className={this.state.activeTab === "2" ? "active" : ""}
                            onClick={() => {
                              this.toggle("2");
                            }}
                            style={{ cursor: "pointer" }}
                          >
                            <i className="tim-icons icon-palette" />
                            Graphics Design
                          </NavLink>
                        </NavItem>
                        <NavItem className="m-auto">
                          <NavLink
                            className={this.state.activeTab === "3" ? "active" : ""}
                            onClick={() => {
                              this.toggle("3");
                            }}
                            style={{ cursor: "pointer" }}
                          >
                            <i className="tim-icons icon-vector" />
                            Digital Marketing
                          </NavLink>
                        </NavItem>
                        <NavItem className="m-auto">
                          <NavLink
                            className={this.state.activeTab === "4" ? "active" : ""}
                            onClick={() => {
                              this.toggle("4");
                            }}
                            style={{ cursor: "pointer" }}
                          >
                            <i className="tim-icons icon-video-66" />
                            Promotional Videos
                          </NavLink>
                        </NavItem>
                        <NavItem className="mx-auto">
                          <NavLink
                            className={this.state.activeTab === "5" ? "active" : ""}
                            onClick={() => {
                              this.toggle("5");
                            }}
                            style={{ cursor: "pointer" }}
                          >
                            <i className="tim-icons icon-support-17" />
                            Troubleshoot {"&"} Support
                          </NavLink>
                        </NavItem>
                      </Nav>
                      <TabContent
                        className="tab-space"
                        activeTab={"project" + this.state.activeTab}
                      >
                        <TabPane tabId="project1">
                          <Row className="mt-5">
                            <Col md="4">
                              <Card>
                                <div className="card-image h-300">
                                  <img
                                    alt="..."
                                    className="img rounded"
                                    src={require("assets/img/projects/thumbnail-freechoicegroup2.jpg")}
                                  />
                                </div>
                                <CardBody className="text-left">
                                  <CardFooter className="mt-0">
                                    {
                                      <div className="stats stats-right">
                                        <div className="stars text-warning">
                                          <i className="tim-icons icon-square-pin ml-2" />
                                          <span>Australia</span>
                                        </div>
                                      </div>
                                    }
                                    <div className="author">
                                      <img
                                        alt="..."
                                        className="avatar img-raised"
                                        src={require("assets/img/projects/freechoice-favico.jpg")}
                                      />
                                      <span className="ml-1">FreeChoice Group</span>
                                    </div>
                                  </CardFooter>
                                </CardBody>
                              </Card>
                            </Col>
                            <Col md="4">
                              <Card>
                                <div className="card-image h-100">
                                  <img
                                    alt="..."
                                    className="img rounded"
                                    src={require("assets/img/projects/thumbnail-drcleanaustralia.jpg")}
                                  />
                                </div>
                                <CardBody className="text-left">
                                  <CardFooter className="mt-0">
                                    <div className="stats stats-right text-primary">
                                      <i className="tim-icons icon-square-pin ml-2" />
                                      <span>Australia</span>
                                    </div>
                                    <div className="author">
                                      <img
                                        alt="..."
                                        className="avatar img-raised"
                                        src={require("assets/img/projects/thumbnail-drcleanaustralia.jpg")}
                                      />
                                      <span className="ml-1">
                                        DR. CLEAN Australia
                                      </span>
                                    </div>
                                  </CardFooter>
                                </CardBody>
                              </Card>
                            </Col>
                            <Col md="4">
                              <Card>
                                <div className="card-image h-100">
                                  <img
                                    alt="..."
                                    className="img rounded"
                                    src={require("assets/img/projects/thumbnail-unic.jpg")}
                                  />
                                </div>
                                <CardBody className="text-left">
                                  <CardFooter className="mt-0">
                                    <div className="stats stats-right text-danger">
                                      <i className="tim-icons icon-square-pin ml-2" />
                                      <span>Nepal</span>
                                    </div>
                                    <div className="author">
                                      <img
                                        alt="..."
                                        className="avatar img-raised"
                                        src={require("assets/img/projects/thumbnail-unic.jpg")}
                                      />
                                      <span className="ml-1">UNIC Nepal</span>
                                    </div>
                                  </CardFooter>
                                </CardBody>
                              </Card>
                            </Col>
                            <blockquote className="blockquote mt-5">
                              <p className="mb-0">
                                Looking for an experienced team to build a new website or transform your existing into new modern design with engaging websites which offer best in user experience.
                              </p>
                              <footer className="blockquote-footer">
                                Give us a chance to serve you! <cite title="Source Title">by - Empresa Team</cite>
                              </footer>
                            </blockquote>
                          </Row>
                        </TabPane>
                        <TabPane tabId="project2">
                          <Row className="mt-5">
                            <Col md="4">
                              <Card>
                                <div className="card-image h-300">
                                  <img
                                    alt="..."
                                    className="img rounded"
                                    src={require("assets/img/projects/canneptrade.jpg")}
                                  />
                                </div>
                                <CardBody className="text-left">
                                  <CardFooter className="mt-0">
                                    {
                                      <div className="stats stats-right">
                                        <div className="stars text-warning">
                                          <i className="tim-icons icon-square-pin ml-2" />
                                          <span>Canada</span>
                                        </div>
                                      </div>
                                    }
                                    <div className="author">
                                      <img
                                        alt="..."
                                        className="avatar img-raised"
                                        src={require("assets/img/projects/canneptrade.jpg")}
                                      />
                                      <span className="ml-1">CANNEPTRADE</span>
                                    </div>
                                  </CardFooter>
                                </CardBody>
                              </Card>
                            </Col>
                            <Col md="4">
                              <Card>
                                <div className="card-image h-100">
                                  <img
                                    alt="..."
                                    className="img rounded"
                                    src={require("assets/img/chhitosewa-thumbnail-1-hd.jpg")}
                                  />
                                </div>
                                <CardBody className="text-left">
                                  <CardFooter className="mt-0">
                                    <div className="stats stats-right text-danger">
                                      <i className="tim-icons icon-square-pin ml-2" />
                                      <span>Nepal</span>
                                    </div>
                                    <div className="author">
                                      <img
                                        alt="..."
                                        className="avatar img-raised"
                                        src={require("assets/img/chhitosewa-thumbnail-1-hd.jpg")}
                                      />
                                      <span className="ml-1">Chhito Sewa</span>
                                    </div>
                                  </CardFooter>
                                </CardBody>
                              </Card>
                            </Col>
                            <Col md="4">
                              <Card>
                                <div className="card-image h-100">
                                  <img
                                    alt="..."
                                    className="img rounded"
                                    src={require("assets/img/projects/helloSplash315_12_8R.png")}
                                  />
                                </div>
                                <CardBody className="text-left">
                                  <CardFooter className="mt-0">
                                    <div className="stats stats-right text-primary">
                                      <i className="tim-icons icon-square-pin ml-2" />
                                      <span>Nepal</span>
                                    </div>
                                    <div className="author">
                                      <img
                                        alt="..."
                                        className="avatar img-raised"
                                        src={require("assets/img/projects/helloSplash315_12_8R.png")}
                                      />
                                      <span className="ml-1">
                                        Hello Cycles
                                      </span>
                                    </div>
                                  </CardFooter>
                                </CardBody>
                              </Card>
                            </Col>
                            <blockquote className="blockquote mt-5">
                              <p className="mb-0">
                                Looking for a creative Graphic's designer to give your brand own/unique identity in this competitive digital market?
                              </p>
                              <footer className="blockquote-footer">
                                Give us a chance to make your own brand! <cite title="Source Title">by - Empresa Team</cite>
                              </footer>
                            </blockquote>
                          </Row>
                        </TabPane>
                        <TabPane tabId="project3">
                          <div className="space-100" />
                          <Col className="ml-auto mr-auto text-center mt-4" md="8">
                            <blockquote className="blockquote mt-5">
                              <p className="mb-0">
                                Looking for a digital marketer?
                              </p>
                              <footer className="blockquote-footer">
                                Give us a chance to build your audiences in this digital Era! <cite title="Source Title">by - Empresa Team</cite>
                              </footer>
                            </blockquote>
                          </Col>
                          <div className="space-100" />
                        </TabPane>
                        <TabPane tabId="project4">
                          <div className="space-100" />
                          <Col className="ml-auto mr-auto text-center mt-4" md="8">
                            <p className="description mb-5">
                              Coming soon!
                            </p>
                          </Col>
                          <div className="space-100" />
                        </TabPane>
                        <TabPane tabId="project5">
                          <div className="space-100" />
                          <Col className="ml-auto mr-auto text-center mt-4" md="8">
                            <p className="description mb-5">
                              Coming soon!
                            </p>
                          </Col>
                          <div className="space-100" />
                        </TabPane>
                      </TabContent>
                    </Container>
                  </div>
                  {/* ********* END PROJECTS 2 ********* */}
                </section>
              </section>

              {/* ********* BLOGS 1 ********* */}
              <div
                className="section blogs-1"
                id="blogs-1"
                style={{ paddingTop: "0px" }}
              >
                <Container>
                  <Row>
                    <Col className="" md="8" style={{ marginBottom: "50px" }}>
                      <hr className="line-success" />
                      <h6 className="category text-muted">Social platforms</h6>
                      <h2 className="title mt-0">Our recent projects</h2>
                    </Col>
                  </Row>
                  <Row className="align-items-center">
                    <Col lg="6">
                      <Card
                        className="card-blog card-background"
                        data-animation={true}
                      >
                        <div
                          className="full-background"
                          style={{
                            backgroundImage:
                              "url(" +
                              require("assets/img/empresa-shopping-thumbnail-1-hd.png") +
                              ")",
                          }}
                        />
                        <CardBody>
                          <div className="content-bottom">
                            <h6 className="card-category">
                              E-Commerce wholesale/retailers platform
                            </h6>
                            <a href="https://www.instagram.com/empresashopping/">
                              <CardTitle tag="h3">Empresa Shopping</CardTitle>
                            </a>
                          </div>
                        </CardBody>
                      </Card>
                    </Col>
                    <Col lg="3">
                      <Card
                        className="card-blog card-background"
                        data-animation={true}
                      >
                        <div
                          className="full-background"
                          style={{
                            backgroundImage:
                              "url(" +
                              require("assets/img/empresa-designs-thumbnail-hd.jpg") +
                              ")",
                          }}
                        />
                        <CardBody>
                          <div className="content-bottom">
                            <h6 className="card-category">Enlighten your Brand</h6>
                            <a href="https://www.instagram.com/empresadesigns/">
                              <CardTitle tag="h3">Empresa Designs</CardTitle>
                            </a>
                          </div>
                        </CardBody>
                      </Card>
                    </Col>
                    <Col lg="3">
                      <Card
                        className="card-blog card-background"
                        data-animation={true}
                      >
                        <div
                          className="full-background"
                          style={{
                            backgroundImage:
                              "url(" +
                              require("assets/img/chhitosewa-thumbnail-1-hd.jpg") +
                              ")",
                          }}
                        />
                        <CardBody>
                          <div className="content-bottom">
                            <h6 className="card-category">
                              Online Service Provider
                            </h6>
                            <a href="https://www.instagram.com/chhitosewa/">
                              <CardTitle tag="h3">Chhito Sewa</CardTitle>
                            </a>
                          </div>
                        </CardBody>
                      </Card>
                    </Col>
                  </Row>
                  <Row>
                    <Col className="ml-auto mr-auto text-center mt-4" md="8">
                      <h3 className="title">Interested in our projects?</h3>
                      <h4 className="description mb-5">
                        Are you ready to bring your ideas into our
                        working days and into your life?
                        <br />
                        Great Ideas require great Mindset!
                        <br />
                        Let's get it.
                      </h4>

                      <Link to={"/contact-us"}>
                        <Button color="primary" size="lg">
                          Contact us
                        </Button>
                      </Link>
                    </Col>
                  </Row>
                </Container>
              </div>
              {/* ********* END BLOGS 1 ********* */}

              <section className="section section-lg">
                <img
                  alt="..."
                  className="path"
                  src={require("assets/img/path4.png")}
                />
                <img
                  alt="..."
                  className="path2"
                  src={require("assets/img/path5.png")}
                />
                <img
                  alt="..."
                  className="path3"
                  src={require("assets/img/path2.png")}
                />
                <Container>
                  <Row className="justify-content-center">
                    <Col lg="12">
                      <h1 className="text-center">Your best benefit</h1>
                      <Row className="row-grid justify-content-center">
                        <Col lg="3">
                          <div className="info">
                            <div className="icon icon-primary">
                              <i className="tim-icons icon-money-coins" />
                            </div>
                            <h4 className="info-title">Low Budget</h4>
                            <hr className="line-primary" />
                            <p>
                              Divide details about your work into parts. Write a few
                              lines about each one. A paragraph describing a feature
                              will.
                            </p>
                          </div>
                        </Col>
                        <Col lg="3">
                          <div className="info">
                            <div className="icon icon-warning">
                              <i className="tim-icons icon-chart-pie-36" />
                            </div>
                            <h4 className="info-title">High Quality</h4>
                            <hr className="line-warning" />
                            <p>
                              Divide details about your product or agency work into
                              parts. Write a few lines about each one. A paragraph
                              describing feature will be a feature.
                            </p>
                          </div>
                        </Col>
                        <Col lg="3">
                          <div className="info">
                            <div className="icon icon-success">
                              <i className="tim-icons icon-single-02" />
                            </div>
                            <h4 className="info-title">Best customer response</h4>
                            <hr className="line-success" />
                            <p>
                              Divide details about your product or agency work into
                              parts. Write a few lines about each one. A paragraph
                              describing be enough.
                            </p>
                          </div>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Container>
              </section>
              <section className="section section-lg section-safe">
                <img
                  alt="..."
                  className="path"
                  src={require("assets/img/path5.png")}
                />
                <Container>
                  <Row className="row-grid justify-content-between">
                    <Col md="5">
                      <img
                        alt="..."
                        className="img-fluid floating"
                        src={require("assets/img/chester-wade.jpg")}
                      />
                      <Card className="card-stats bg-danger">
                        <CardBody>
                          <div className="justify-content-center">
                            <div className="numbers">
                              <CardTitle tag="p">100%</CardTitle>
                              <p className="card-category text-white">Safe</p>
                            </div>
                          </div>
                        </CardBody>
                      </Card>
                      <Card className="card-stats bg-info">
                        <CardBody>
                          <div className="justify-content-center">
                            <div className="numbers">
                              <CardTitle tag="p">100+</CardTitle>
                              <p className="card-category text-white">
                                Satisfied customers
                              </p>
                            </div>
                          </div>
                        </CardBody>
                      </Card>
                      <Card className="card-stats bg-default">
                        <CardBody>
                          <div className="justify-content-center">
                            <div className="numbers">
                              <CardTitle tag="p">20+</CardTitle>
                              <p className="card-category text-white">Business</p>
                            </div>
                          </div>
                        </CardBody>
                      </Card>
                    </Col>
                    <Col md="6">
                      <div className="px-md-5">
                        <hr className="line-success" />
                        <h3>Awesome features</h3>
                        <p>
                          The design system of Empresa is pre-built with well
                          experienced developers to help you get started faster. You
                          can simply provide your specifications and you're good to
                          go.
                        </p>
                        <ul className="list-unstyled mt-5">
                          <li className="py-2">
                            <div className="d-flex align-items-center">
                              <div className="icon icon-success mb-2">
                                <i className="tim-icons icon-vector" />
                              </div>
                              <div className="ml-3">
                                <h6>Carefully designed components</h6>
                              </div>
                            </div>
                          </li>
                          <li className="py-2">
                            <div className="d-flex align-items-center">
                              <div className="icon icon-success mb-2">
                                <i className="tim-icons icon-tap-02" />
                              </div>
                              <div className="ml-3">
                                <h6>Amazing Products</h6>
                              </div>
                            </div>
                          </li>
                          <li className="py-2">
                            <div className="d-flex align-items-center">
                              <div className="icon icon-success mb-2">
                                <i className="tim-icons icon-single-02" />
                              </div>
                              <div className="ml-3">
                                <h6>Super friendly support team</h6>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </Col>
                  </Row>
                </Container>
              </section>

              <div
                className="pricing-6 section-pricing-6 section-image"
                id="pricing-6"
                style={{
                  backgroundImage: "url(" + require("assets/img/bg31.jpg") + ")",
                  marginTop: "80px",
                }}
              >
                <Container>
                  <Row>
                    <Col md="4">
                      <hr className="line-primary" />
                      <h2 className="title">Choose a plan for your next project</h2>
                      <Nav className="nav-pills-primary" pills role="tablist">
                        <NavItem>
                          <NavLink
                            className={this.state.thirdTabs === "1" ? "active" : ""}
                            onClick={() => {
                              this.priceToggle("thirdTabs", "1");
                              this.priceToggle()
                            }}
                            style={{ cursor: "pointer" }}
                          >
                            Expensive
                          </NavLink>
                        </NavItem>
                        <NavItem>
                          <NavLink
                            className={this.state.thirdTabs === "2" ? "active" : ""}
                            onClick={() => {
                              this.priceToggle("thirdTabs", "2");
                            }}
                            style={{ cursor: "pointer" }}
                          >
                            Cheaper
                          </NavLink>
                        </NavItem>
                      </Nav>
                      <p className="description">
                        You have Free Unlimited Updates and Premium Support on each
                        package. You also have 20 days to request a refund if you're
                        not happy with your purchase.
                      </p>
                    </Col>
                    <Col className="ml-auto mr-auto" md="7">
                      <TabContent
                        className="tab-space"
                        activeTab={"thirdTabs" + this.state.thirdTabs}
                      >
                        <TabPane tabId="thirdTabs1">
                          <Row>
                            <Col md="6">
                              <Card className="card-pricing card-raised">
                                <CardBody>
                                  <h6 className="category">Plus</h6>
                                  <CardTitle tag="h1">
                                    <small>$</small>
                                    100
                                  </CardTitle>
                                  <ul>
                                    <li>
                                      <b>15</b> Projects
                                    </li>
                                    <li>
                                      <b>5GB</b> Storage
                                    </li>
                                    <li>
                                      <b>Unlimited</b> users
                                    </li>
                                    <li>
                                      <b>No time</b> tracking
                                    </li>
                                  </ul>
                                  <Button
                                    className="btn-round"
                                    color="primary"
                                    href="#pablo"
                                    onClick={(e) => e.preventDefault()}
                                  >
                                    Buy Now!
                                  </Button>
                                </CardBody>
                              </Card>
                            </Col>
                            <Col md="6">
                              <Card className="card-pricing card-plain">
                                <CardBody>
                                  <h6 className="category">Maximum</h6>
                                  <CardTitle tag="h1">
                                    <small>$</small>
                                    120
                                  </CardTitle>
                                  <ul>
                                    <li>
                                      <b>25</b> Projects
                                    </li>
                                    <li>
                                      <b>15GB</b> Storage
                                    </li>
                                    <li>
                                      <b>Unlimited</b> users
                                    </li>
                                    <li>
                                      <b>No time</b> tracking
                                    </li>
                                  </ul>
                                  <Button
                                    className="btn-round"
                                    color="primary"
                                    href="#pablo"
                                    onClick={(e) => e.preventDefault()}
                                  >
                                    Buy Now!
                                  </Button>
                                </CardBody>
                              </Card>
                            </Col>
                          </Row>
                        </TabPane>
                        <TabPane tabId="thirdTabs2">
                          <Row>
                            <Col md="6">
                              <Card className="card-pricing card-raised">
                                <CardBody>
                                  <h6 className="category">Standard</h6>
                                  <CardTitle tag="h1">
                                    <small>$</small>
                                    20
                                  </CardTitle>
                                  <ul>
                                    <li>
                                      <b>5</b> Projects
                                    </li>
                                    <li>
                                      <b>2GB</b> Storage
                                    </li>
                                    <li>
                                      <b>Unlimited</b> users
                                    </li>
                                    <li>
                                      <b>No time</b> tracking
                                    </li>
                                  </ul>
                                  <Button
                                    className="btn-round"
                                    color="primary"
                                    href="#pablo"
                                    onClick={(e) => e.preventDefault()}
                                  >
                                    Buy Now!
                                  </Button>
                                </CardBody>
                              </Card>
                            </Col>
                            <Col md="6">
                              <Card className="card-pricing card-plain">
                                <CardBody>
                                  <h6 className="category">Premium</h6>
                                  <CardTitle tag="h1">
                                    <small>$</small>
                                    60
                                  </CardTitle>
                                  <ul>
                                    <li>
                                      <b>7</b> Projects
                                    </li>
                                    <li>
                                      <b>3GB</b> Storage
                                    </li>
                                    <li>
                                      <b>Unlimited</b> users
                                    </li>
                                    <li>
                                      <b>No time</b> tracking
                                    </li>
                                  </ul>
                                  <Button
                                    className="btn-round"
                                    color="primary"
                                    href="#pablo"
                                    onClick={(e) => e.preventDefault()}
                                  >
                                    Buy Now!
                                  </Button>
                                </CardBody>
                              </Card>
                            </Col>
                          </Row>
                        </TabPane>
                      </TabContent>
                    </Col>
                  </Row>
                </Container>
              </div>
              {/* ********* END PRICING 6 ********* */}
              <DemoFooter />
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default graphql(GET_SERVICES_SERVICE_CATLOG)(ServicesPage);
