import fetch from "node-fetch";

// Sample of using node-fetch
// fetch('https://api.hashnode.com', {
//     method: 'GET',
//     headers: {
//         'Content-Type': 'application/json',
//         Authorization: '<DEV_KEY>',
//     },
//     body
// })

export async function gql(query, variables = {}) {
  const data = await fetch("https://api.hashnode.com/", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      query,
      variables,
    }),
  });
  return data.json();
}

export const GET_USER_ARTICLES = `
  query getUserArticles($page: Int!, $user: String!) {
    user(username: $user) {
      publication {
        posts(page: $page) {
          _id
          title
          brief
          contentMarkdown
          author {
            username
            photo
            name
          }
          coverImage
          dateAdded
          slug
        }
      }
    }
  }
`;

export const GET_USER_INFO = `
  query getUserInfo($page: Int!, $user: String!) {
    user(username: $user) {
      _id
      name
      username
      tagline
      photo
      coverImage
      publication {
        posts(page: $page) {
          _id
          title
          brief
          coverImage
          dateAdded
          slug
        }
      }
      socialMedia {
        twitter
        facebook
        linkedin
        github
      }
    }
  }
`;
