import React from "react";
// nodejs library that concatenates classes
import classnames from "classnames";

// Importing GQL Fetch function
import { gql as graphQL } from "../../services/blog-api-service";
// Importing GraphQL query
import { GET_USER_ARTICLES, GET_USER_INFO } from "../../services/blog-api-service";

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardTitle,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Table,
  Container,
  Row,
  Col,
} from "reactstrap";

// React router dom
import { Link } from "react-router-dom";

// core components
import ColorNavbar from "components/Navbars/ColorNavbar.js";
import DemoFooter from "components/Footers/DemoFooter.js";
import moment from "moment";

// Importing Teams data
import { Teams as users } from "../../data/team";

const getReadingTime = (article) => {
  if (article) {
    let int = article.split(" ").length;
    let time = int / 200 < 1 ? 1 : Math.floor(int / 200);
    return `${time} min${time > 1 ? "s" : ""}`;
  }
};

const GenerateBlogCards = (props) => {
  return !props.blogs.length > 0 ? (
    <div className="ml-auto mr-auto text-center text-white">Loading...</div>
  ) : (
    <>
      {props.blogs.map((blog, id) => {
        return (
          <Col lg="4" md="6" key={id}>
            <Card className="card-blog card-plain">
              <div className="card-image">
                <Link to={"/blog-post/" + blog.slug}>
                  <img
                    alt="..."
                    className="img rounded"
                    src={blog.coverImage}
                    style={{ objectFit: "cover" }}
                  />
                </Link>
              </div>
              <CardBody>
                <h6 className="category text-primary">Features</h6>
                <CardTitle tag="h4">
                  <Link to={"/blog-post/" + blog.slug}>
                    {blog.title.substring(0, 52)} ...
                  </Link>
                </CardTitle>
                <p className="card-description">
                  {blog.brief.substring(0, 150)}...
                </p>
                <CardFooter>
                  <div className="author">
                    <img
                      alt="..."
                      className="avatar img-raised"
                      src={blog.author.photo}
                      style={{ objectFit: "cover" }}
                    />
                    <span className="ml-1">{blog.author.username}</span>
                  </div>
                  <div className="stats stats-right">
                    <i className="tim-icons icon-watch-time" />{" "}
                    {getReadingTime(blog.contentMarkdown)} read
                  </div>
                </CardFooter>
              </CardBody>
            </Card>
          </Col>
        );
      })}
    </>
  );
};

const GenerateTrendingBlogCards = (props) => {
  return !props.blogs ? (
    <div className="ml-auto mr-auto text-center">Loading...</div>
  ) : (
    <>
      {props.blogs.slice(0, 3).map((blog, id) => {
        return (
          <Card className="card-blog card-plain blog-horizontal" key={id}>
            <Row>
              <Col lg="4">
                <div className="card-image">
                  <a
                    href="#pablo"
                    onClick={(e) => e.preventDefault()}
                  >
                    <img
                      alt="..."
                      className="img rounded"
                      src={blog.coverImage}
                    />
                  </a>
                </div>
              </Col>
              <Col lg="8">
                <CardBody>
                  <CardTitle tag="h3">
                    <Link to={"/blog-post/" + blog.slug}>
                      {blog.title}
                    </Link>
                  </CardTitle>
                  <p className="card-description">
                    {blog.brief}{" "}
                    <Link to={"/blog-post/" + blog.slug}>
                      Read more
                    </Link>
                  </p>
                  <div className="author">
                    <img
                      alt="..."
                      className="avatar img-raised"
                      src={blog.author.photo}
                    />
                    <div className="text">
                      <span className="name">{blog.author.username}</span>
                      <div className="meta">{moment(blog.dateAdded).fromNow()}</div>
                    </div>
                  </div>
                </CardBody>
              </Col>
            </Row>
          </Card>
        );
      })}
    </>
  )
}

const GenerateFreshStoriesCards = (props) => {
  return !props.blogs ? (
    <div className="ml-auto mr-auto text-center">Loading...</div>
  ) : (
    <>
      {props.blogs.slice(0, 4).map((blog, id) => {
        return (
          <Col lg="3" key={id}>
            <Card
              className="card-blog card-background"
              data-animation="zooming"
            >
              <img
                alt="..."
                className="full-background"
                src={blog.coverImage}
                style={{ objectFit: "cover" }}
              />
              <CardBody>
                <div className="content-bottom">
                  <h6 className="card-category">{blog.author.username}</h6>
                  <a href="">
                    <CardTitle tag="h3">
                      <Link to={"/blog-post/" + blog.slug} className="text-white">
                        {blog.title}
                      </Link>
                    </CardTitle>
                  </a>
                </div>
              </CardBody>
            </Card>
          </Col>
        )
      })}
    </>
  )
}

const GenerateAuthorCards = (props) => {
  return !Object.keys(props.authors).length ? (
    <div className="ml-auto mr-auto text-center">Loading...</div>
  ) : (
    <>
      {
        Object.entries(props.authors).map(([username, userInfo], id) => {
          return (
            <Col md="4" key={username}>
              <Card className="card-profile">
                <div className="card-image">
                  <h4 className="title">@ {userInfo.username}</h4>
                  {/* <UncontrolledDropdown>
                    <DropdownToggle
                      aria-expanded={false}
                      caret
                      className="btn-icon"
                      color="link"
                      data-toggle="dropdown"
                      type="button"
                    >
                      <i className="tim-icons icon-settings-gear-63" />
                    </DropdownToggle>
                    <DropdownMenu right x-placement="bottom-end">
                      <DropdownItem
                        href="#pablo"
                        onClick={(e) => e.preventDefault()}
                      >
                        Edit Profile
                      </DropdownItem>
                      <DropdownItem
                        href="#pablo"
                        onClick={(e) => e.preventDefault()}
                      >
                        Settings
                      </DropdownItem>
                      <DropdownItem
                        href="#pablo"
                        onClick={(e) => e.preventDefault()}
                      >
                        Log out
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown> */}
                  <a href="#pablo" onClick={(e) => e.preventDefault()}>
                    <img
                      alt="..."
                      className="img img-raised rounded"
                      src={userInfo.photo}
                    />
                  </a>
                </div>
                <CardBody>
                  <hr className="line-primary" />
                  <h3 className="job-title">{userInfo.name}</h3>
                  <Table className="tablesorter" responsive>
                    <tbody>
                      <tr>
                        <td className="text-left">
                          <i className="tim-icons icon-atom" /> Skills
                        </td>
                        <td className="text-right">
                          {
                            props.teams.find
                              (team => team.username === userInfo.username)
                              .profileInfoCard.skills.fields.map(
                                (skill, id) =>
                                  `${id > 2 ? '' : skill.name} ${id > 1 ? '' : ' | '}`
                              )
                          }
                        </td>
                      </tr>
                      <tr>
                        <td className="text-left">
                          <i className="tim-icons icon-vector" />{" "}
                          Social
                        </td>
                        <td className="text-right">
                          <Button
                            className="btn-icon btn-round btn-simple"
                            href={userInfo.socialMedia.facebook}
                          >
                            <i className="fab fa-facebook" />
                          </Button>
                          <Button
                            className="btn-icon btn-round btn-simple ml-2"
                            href={userInfo.socialMedia.twitter}
                          >
                            <i className="fab fa-twitter" />
                          </Button>
                          <Button
                            className="btn-icon btn-round btn-simple ml-2"
                            href={userInfo.socialMedia.linkedin}
                          >
                            <i className="fab fa-linkedin" />
                          </Button>
                          {/* <i className="fab fa-facebook" />
                          <i className="fab fa-twitter" style={{ marginLeft: '4px' }} />
                          <i className="fab fa-linkedin" style={{ marginLeft: '4px' }} />
                          <i className="fab fa-github" style={{ marginLeft: '4px' }} /> */}
                        </td>
                      </tr>
                      <tr>
                        <td className="text-left">
                          <i className="tim-icons icon-chart-bar-32" />{" "}
                          Articles
                        </td>
                        <td className="text-right">{userInfo.publication.posts.length}</td>
                      </tr>
                    </tbody>
                  </Table>
                </CardBody>
              </Card>
            </Col>
          )
        })
      }
    </>
  )
}


class BlogPosts extends React.Component {
  constructor(props) {
    super(props);
    let windowScrollTop;
    if (window.innerWidth >= 768) {
      windowScrollTop = window.pageYOffset / 3;
    } else {
      windowScrollTop = 0;
    }
    this.state = {
      transform: "translate3d(0," + windowScrollTop + "px,0)",
      teams: users,
      blogs: [],
      authors: {}
    };
  }
  componentDidMount() {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    this.refs.wrapper.scrollTop = 0;
    document.body.classList.add("blog-posts");
    if (window.innerWidth >= 768) {
      var windowScrollTop = window.pageYOffset / 3;
      this.setState({
        transform: "translate3d(0," + windowScrollTop + "px,0)",
      });
      window.addEventListener("scroll", this.resetTransform);
    }

    // Fetching bolg articles using GraphQL
    this.state.teams.map((team) => {
      graphQL(GET_USER_ARTICLES, {
        page: 0,
        user: team.username,
      }).then((result) => {
        let articles = result.data.user.publication;
        let posts = this.state.blogs;
        if (articles) {
          articles.posts.map((article) => {
            posts.push(article);
          });
          this.setState({
            blogs: posts.sort(
              (a, b) =>
                new moment(b.dateAdded).format("YYYYMMDD") -
                new moment(a.dateAdded).format("YYYYMMDD")
            ),
          });
        } else {
          console.log(`No article(s) found!`);
        }
      });

      // Fetching all user informatuon 
      graphQL(GET_USER_INFO, {
        page: 0,
        user: team.username,
      }).then((result) => {
        let info = result.data.user;
        let tempUser = this.state.authors;
        if (info) {
          tempUser[team.username] = info;
        } else {
          console.log(`This '${this.state.username}' not found in HashNode!`);
        }
        this.setState({
          authors: tempUser
        });
      });
    });
  }
  componentWillUnmount() {
    document.body.classList.remove("blog-posts");
    if (window.innerWidth >= 768) {
      window.removeEventListener("scroll", this.resetTransform);
    }
  }
  resetTransform = () => {
    var windowScrollTop = window.pageYOffset / 3;
    this.setState({
      transform: "translate3d(0," + windowScrollTop + "px,0)",
    });
  };

  render() {
    return (
      <>
        <ColorNavbar />
        <div className="wrapper" ref="wrapper">
          <div className="page-header page-header-small header-filter">
            <div
              className="page-header-image"
              style={{
                backgroundImage:
                  "url(" + require("assets/img/ruvim-noga.jpg") + ")",
                transform: this.state.transform,
              }}
            />
            <div className="content-center">
              <Row>
                <Col className="ml-auto mr-auto text-center" md="6">
                  <h1 className="title">
                    A Place for Entrepreneurs to Share and Discover New Stories!
                  </h1>
                  {/* <Button
                    className="btn-round btn-icon"
                    color="primary"
                    href="#pablo"
                    onClick={(e) => e.preventDefault()}
                  >
                    <i className="fab fa-twitter" />
                  </Button>
                  <Button
                    className="btn-round btn-icon ml-1"
                    color="primary"
                    href="#pablo"
                    onClick={(e) => e.preventDefault()}
                  >
                    <i className="fab fa-instagram" />
                  </Button> */}
                </Col>
              </Row>
            </div>
          </div>
          {/* Fetched Blog cards using GraphQL goes here */}
          <div className="main main-raised">
            <Container>
              <Row>
                <GenerateBlogCards blogs={this.state.blogs} />
              </Row>
            </Container>
            <div className="section">
              <Container>
                {/* Fetched Blog items goes here for Trending posts */}
                <Row>
                  <Col className="ml-auto mr-auto" md="12">
                    <h2 className="title ml-1">Trending now</h2>
                    <br />
                    <GenerateTrendingBlogCards blogs={this.state.blogs} />
                  </Col>
                </Row>
              </Container>
            </div>
            <div className="section blogs-4">
              <Container fluid>
                {/* Fetched Blog items goes here for Fresh stories */}
                <h1 className="title text-center">
                  Stay tuned with our fresh stories
                </h1>
                <br />
                <Row>
                  <GenerateFreshStoriesCards blogs={this.state.blogs} />
                </Row>
              </Container>
            </div>
            <div className="section team-4">
              <Container>
                <h2 className="title text-center">Our Authors</h2>
                <br />
                <Row>
                  {/* Fetched user info goes here for Author cards */}
                  <GenerateAuthorCards authors={this.state.authors} teams={users} />
                </Row>
              </Container>
            </div>
            {/* <div className="title text-center">
              <h3>Show us some love</h3>
            </div>
            <div className="social-line social-line-big-icons">
              <Container>
                <Row>
                  <Col md="2">
                    <Button
                      className="btn-icon btn-simple btn-footer"
                      color="twitter"
                      href="#pablo"
                      onClick={(e) => e.preventDefault()}
                    >
                      <i className="fab fa-twitter" />
                    </Button>
                  </Col>
                  <Col md="2">
                    <Button
                      className="btn-icon btn-simple btn-footer"
                      color="facebook"
                      href="#pablo"
                      onClick={(e) => e.preventDefault()}
                    >
                      <i className="fab fa-facebook-square" />
                    </Button>
                  </Col>
                  <Col md="2">
                    <Button
                      className="btn-icon btn-simple btn-footer"
                      color="google"
                      href="#pablo"
                      onClick={(e) => e.preventDefault()}
                    >
                      <i className="fab fa-google-plus" />
                    </Button>
                  </Col>
                  <Col md="2">
                    <Button
                      className="btn-icon btn-simple btn-footer"
                      color="dribbble"
                      href="#pablo"
                      onClick={(e) => e.preventDefault()}
                    >
                      <i className="fab fa-dribbble" />
                    </Button>
                  </Col>
                  <Col md="2">
                    <Button
                      className="btn-icon btn-simple btn-footer"
                      color="youtube"
                      href="#pablo"
                      onClick={(e) => e.preventDefault()}
                    >
                      <i className="fab fa-youtube" />
                    </Button>
                  </Col>
                  <Col md="2">
                    <Button
                      className="btn-icon btn-simple btn-instagram btn-footer"
                      color="default"
                      href="#pablo"
                      onClick={(e) => e.preventDefault()}
                    >
                      <i className="fab fa-instagram" />
                    </Button>
                  </Col>
                </Row>
              </Container>
            </div> */}
            <div className="subscribe-line">
              <Container>
                <Row>
                  <Col className="ml-auto mr-auto" lg="8" xs="10">
                    <div className="text-center">
                      <h4 className="title">Subscribe to our Newsletter</h4>
                      <p className="description">
                        Join our newsletter and get news in your inbox every
                        week! We hate spam too, so no worries about this.
                      </p>
                    </div>
                    <Card className="card-raised card-form-horizontal">
                      <CardBody>
                        <Form action="" method="">
                          <Row>
                            <Col sm="8">
                              <InputGroup
                                className={classnames({
                                  "input-group-focus": this.state.emailFocus,
                                })}
                              >
                                <InputGroupAddon addonType="prepend">
                                  <InputGroupText>
                                    <i className="tim-icons icon-email-85" />
                                  </InputGroupText>
                                </InputGroupAddon>
                                <Input
                                  placeholder="Your Email..."
                                  type="email"
                                  onFocus={(e) =>
                                    this.setState({ emailFocus: true })
                                  }
                                  onBlur={(e) =>
                                    this.setState({ emailFocus: false })
                                  }
                                />
                              </InputGroup>
                            </Col>
                            <Col sm="4">
                              <Button block color="primary" type="button">
                                Subscribe
                              </Button>
                            </Col>
                          </Row>
                        </Form>
                      </CardBody>
                    </Card>
                  </Col>
                </Row>
              </Container>
            </div>
          </div>
          <DemoFooter />
        </div>
      </>
    );
  }
}

export default BlogPosts;
