import React from "react";
import { Link } from "react-router-dom";


import gql from 'graphql-tag';
import { graphql } from 'react-apollo';

// reactstrap components
import {
  Button,
  NavItem,
  NavLink,
  Nav,
  Container,
  Row,
  Col,
  UncontrolledTooltip
} from "reactstrap";

const GET_FOOTER = gql`
  {
	  navBar{
      brandName
      menuList{
        title
        url
      }
    }
}
`;

class DemoFooter extends React.Component {
  state = {
    brandName: "",
    menuList: []
  }
  getFooterData() {
    var data = this.props.data;
    if (!data.loading) {
      const getFooterData = {
        brandName: data.navBar[0].brandName,
        menuList: data.navBar[0].menuList
      }
      this.setState({
        brandName: getFooterData.brandName,
        menuList: getFooterData.menuList
      })
      console.log(this.state.menuList);
    }
  };

  generateFooterItem() {
    if (this.state.menuList.length !== 0) {
      return (
        this.state.menuList.map(item => {
          return (
            <Col md="3" xs="6">
              <Nav>
                <NavItem>
                  <NavLink key={item.id} to={item.url} tag={Link}>
                    {item.title}
                  </NavLink>
                </NavItem>
              </Nav>
            </Col>
          );
        })

      )
    }
  }

  render() {
    return (
      <>
        <footer className="footer" style={{ textAlign: "left" }}>
          <Container>
            <Row>
              <Col md="3">
                <img
                  alt="..."
                  className="img rounded title"
                  src={require("assets/img/empresa-mini-logo-white.png")}
                  height="75px"
                />
              </Col>
              <Col md="3" xs="6">
                <Nav>
                  <NavItem>
                    <NavLink to="/index" tag={Link}>
                      Home
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink to="/services-page" tag={Link}>
                      Services
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink to="/portfolio-page" tag={Link}>
                      Portfolio
                    </NavLink>
                  </NavItem>
                </Nav>
              </Col>
              <Col md="3" xs="6">
                <Nav>
                  <NavItem>
                    <NavLink to="/team" tag={Link}>
                      Team
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink to="/blog-posts" tag={Link}>
                      Blog
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      to="/contact-us" tag={Link}>
                      Contact Us
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      to="/about-us" tag={Link}>
                      About Us
                    </NavLink>
                  </NavItem>
                </Nav>
              </Col>
              <Col md="3">
                <h3 className="title">Follow us:</h3>
                <div className="btn-wrapper profile text-left">
                  <Button
                    className="btn-icon btn-neutral btn-round btn-simple"
                    color="default"
                    href="https://twitter.com/empresaoriginal"
                    id="tooltip39661217"
                    target="_blank"
                  >
                    <i className="fab fa-twitter" />
                  </Button>
                  <UncontrolledTooltip delay={0} target="tooltip39661217">
                    Follow us
                  </UncontrolledTooltip>
                  <Button
                    className="btn-icon btn-neutral btn-round btn-simple ml-1"
                    color="default"
                    href="https://www.facebook.com/empresaoriginal"
                    id="tooltip206037619"
                    target="_blank"
                  >
                    <i className="fab fa-facebook-square" />
                  </Button>
                  <UncontrolledTooltip delay={0} target="tooltip206037619">
                    Like us
                  </UncontrolledTooltip>
                  <Button
                    className="btn-icon btn-neutral btn-round btn-simple ml-1"
                    color="default"
                    href="https://dribbble.com/empresaoriginal"
                    id="tooltip750293512"
                    target="_blank"
                  >
                    <i className="fab fa-dribbble" />
                  </Button>
                  <UncontrolledTooltip delay={0} target="tooltip750293512">
                    Follow us
                  </UncontrolledTooltip>
                </div>
              </Col>
            </Row>
          </Container>
        </footer>
        <Row style={{ backgroundColor: '#222a42', fontSize: '11px', textAlign: 'center', padding: '10px' }}>
          <Container>
            <p>Copyright @2021 Empresa Original, All rights reserved</p>
          </Container>
        </Row>
      </>
    );
  }
}

export default graphql(GET_FOOTER)(DemoFooter);
