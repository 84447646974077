// Importing blogs
import { blogs } from "../data/blog";

export const Teams = [
  {
    id: "0x101",
    name: "Kush Gautam",
    username: "kusgautam",
    tagline: "Co-Founder & CTO",
    description:
      "Experienced Developer and Designer, currently working as a Front End Developer @ BePATIENT, an Alira Health Company and deeply focus into Blockchain.",
    profilePicture: "assets/img/team/kusgautam.jpg",
    socialProfile: [
      { name: "twitter", url: "https://twitter.com/aakarkun" },
      { name: "facebook", url: "https://www.facebook.com/kusgautam" },
      { name: "linkedin", url: "https://www.linkedin.com/in/kusgautam/" },
    ],
    profileInfoCard: {
      skills: {
        headers: ["Field", "Proficency", "Avg Rating"],
        fields: [
          { name: "Developer", rating: "90" },
          { name: "Designer", rating: "85" },
          { name: "Illustrator", rating: "74" },
        ],
      },
      certifications: [
        {
          id: "E141-2003",
          icon: "microsoft",
          title: "Microsoft Certified Professionals",
          description: "Microsoft | Issued on Jan 2013 | No Exp.",
          url: "https://onedrive.live.com/redir?resid=A13D98741CE45A1F!271&authkey=!AMAqauhOAS9CegM&ithint=file%2cpdf",
        },
        {
          id: "E572-3787",
          icon: "microsoft",
          title:
            "Server Virtualization with Windows Server Hyper-V and System Center Specialist",
          description: "Microsoft| Issued on Feb 2014 | No Exp.",
          url: "https://onedrive.live.com/redir?resid=A13D98741CE45A1F!393&authkey=!AIJI5bj8pNUP4OU&ithint=file%2cpdf",
        },
      ],
      articles: []
    },
  },
  {
    id: "0x102",
    name: "Luv Gautam",
    username: "mimos",
    tagline: "Co-Founder & CEO",
    description:
      "A Blockchain Enthusiast who is currently working as a Frontend Developer @Trustwave",
    profilePicture: "assets/img/team/luvgautam.jpg",
    socialProfile: [
      { twitterUrl: "https://twitter.com/meemimos" },
      { facebookUrl: "https://www.facebook.com/meemimos" },
      { linkedinUrl: "https://www.linkedin.com/in/luhvgautam/" },
    ],
    profileInfoCard: {
      skills: {
        headers: ["Field", "Proficency", "Avg Rating"],
        fields: [
          { name: "Developer", rating: "86" },
          { name: "Designer", rating: "90" },
          { name: "Illustrator", rating: "70" },
        ],
      },
      certifications: [
        {
          id: "E141-2003",
          icon: "microsoft",
          title: "Microsoft Certified Professionals",
          description: "Microsoft | Issued on Jan 2013 | No Exp.",
          url: "",
        },
        {
          id: "E572-3787",
          icon: "microsoft",
          title:
            "Server Virtualization with Windows Server Hyper-V and System Center Specialist",
          description: "Microsoft| Issued on Feb 2014 | No Exp.",
          url: "",
        },
      ],
      articles: [],
    },
  },
  {
    id: "0x103",
    name: "Roshika Gyawali",
    username: "roshikagyawali",
    tagline: "Project Lead & CFO",
    description:
      "Over the years, advancement in CRM technology has reshaped the way SMBs and SMEs manage their workflows...",
    profilePicture: "assets/img/team/roshikagyawali.jpg",
    socialProfile: [
      { twitterUrl: "https://twitter.com/roshikagyawali" },
      { facebookUrl: "https://www.facebook.com/roshika.gyawali" },
      { linkedinUrl: "https://www.linkedin.com/in/roshikagyawali" },
    ],
    profileInfoCard: {
      skills: {
        headers: ["Field", "Proficency", "Avg Rating"],
        fields: [
          { name: "Marketing", rating: "75" },
          { name: "Finance", rating: "70" },
          { name: "Admintrator", rating: "80" },
        ],
      },
      certifications: [
        {
          id: "BSB42415",
          icon: "",
          title: "Certified Marketing Specialist",
          description: "Certificate IV in Marketing and Communication",
          url: "",
        },
        {
          id: "DMC2021",
          icon: "",
          title: "Digital Marketing Certified",
          description: "Digital Marketing Certification Course - Australia",
          url: "",
        },
      ],
      articles: [],
    },
  },
];
